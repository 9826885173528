import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

const ProductDetailCard = ({ title, content, hover }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {hover}
    </Tooltip>
  );

  return (
    <Col>
      <Card
        style={{
          borderColor: "#aaa",
          border: "1.5px solid",
          borderRadius: "4px",
          width: "150px",
        }}
        className="text-center"
      >
        <Card.Header
          style={{
            color: "#3f5764",
            backgroundColor: "#ececec",
            borderColor: "#aaa",
            fontWeight: "700",
            borderBottom: "1.5px solid",
            padding: "3px",
          }}
        >
          {title}
        </Card.Header>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={renderTooltip}
        >
          <Card.Body
            style={{
              padding: "3px",
            }}
          >
            {content}
          </Card.Body>
        </OverlayTrigger>
        <Card.Footer className="text-muted"></Card.Footer>
      </Card>
    </Col>
  );
};

export default ProductDetailCard;
