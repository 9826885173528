import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductsStart } from "./../../redux/Products/products.actions";
import "font-awesome/css/font-awesome.min.css";
import { FaFilter } from "react-icons/fa";
import noImage from "./../../assests/no-image-available.jpg";
import { addProduct } from "./../../redux/Cart/cart.actions";
import PopUp from "./../../components/PopUp";
import { MdOutlineExpandMore, MdOutlineExpandLess } from "react-icons/md";
import ProductDetails from "../../components/ProductDetails";
import { FaRegQuestionCircle } from "react-icons/fa";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./styles.scss";
import ProductButtons from "../../components/ProductButtons";

const mapState = ({ productsData }) => ({
  products: productsData.products,
});

const ProductImage = ({ imageLink }) => {
  if (imageLink && imageLink !== "") {
    return <img className="aspect__img" src={imageLink} alt="aspect" />;
  } else {
    return <img className="aspect__img" src={noImage} alt="aspect" />;
  }
};

const Development = (props) => {
  const dispatch = useDispatch();
  const { products } = useSelector(mapState);

  const { data, queryDoc, isLastPage } = products;

  const [category, setCategory] = useState("");
  const [sortBy, setSortBy] = useState("");

  const [buttonPopup, setButtonPopup] = useState(false);

  const [amzCA_BF, setAmzCA_BF] = useState(false);
  const [amzCOM_BF, setAmzCOM_BF] = useState(false);
  const [WAL_BF, setWAL_BF] = useState(false);
  const [WALCA_BF, setWALCA_BF] = useState(false);
  const [EBAY_BF, setEBAY_BF] = useState(false);

  const [amzCA_ST, setAmzCA_ST] = useState(false);
  const [amzCOM_ST, setAmzCOM_ST] = useState(false);

  // const [fastShipping, setFastShipping] = useState(false);
  const [amazonNotComp, setAmazonNotComp] = useState(false);
  const [buyBoxOpp, setBuyBoxOpp] = useState(false);
  const [outOfStock, setOutOfStock] = useState(false);

  const [buyPriceLessThan20, setBuyPriceLessThan20] = useState(false);

  const [notOutOfStock, setNotOutOfStock] = useState(false);

  // const [buyPrice0_30, setBuyPrice0_30] = useState(false);
  // const [buyPirce30_70, setBuyPirce30_70] = useState(false);
  // const [buyPrice_70_, setBuyPrice_70_ ] = useState(false);

  // const [profitRange20_50, setProfitRange20_50] = useState(false);
  // const [profitRange50_100, setProfitRange50_100] = useState(false);
  // const [profitRange_100_, setProfitRange_100_] = useState(false);

  // const [bestSalesRank0_150, setBestSalesRank0_150] = useState(false);
  // const [bestSalesRank150_300, setBestSalesRank150_300] = useState(false);
  // const [bestSalesRank_300_, setBestSalesRank_300_] = useState(false);

  useEffect(() => {
    dispatch(
      fetchProductsStart({
        amzCA_BF,
        amzCOM_BF,
        WALCA_BF,
        WAL_BF,
        EBAY_BF,
        amzCA_ST,
        amzCOM_ST,
        amazonNotComp,
        buyBoxOpp,
        outOfStock,
        notOutOfStock,
        category,
        sortBy,
        buyPriceLessThan20,
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    amzCA_BF,
    amzCOM_BF,
    WALCA_BF,
    WAL_BF,
    EBAY_BF,
    amzCA_ST,
    amzCOM_ST,
    amazonNotComp,
    buyBoxOpp,
    outOfStock,
    notOutOfStock,
    category,
    sortBy,
    buyPriceLessThan20,
  ]);

  const handleAddToCart = (product) => {
    if (!product) return;
    dispatch(addProduct(product));

    setButtonPopup(true);
  };

  const sortByListener = (e) => {
    setSortBy(e.target.value);
  };

  const radioButtonsListener = (e) => {
    setCategory(e.target.value);
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );

  // const flipBestSalesRank0_150 = (e) =>{
  //     setBestSalesRank0_150(e.target.checked);
  // }

  // const flipBestSalesRank150_300 = (e) =>{
  //     setBestSalesRank150_300(e.target.checked);
  // }

  // const flipBestSalesRank_300_ = (e) =>{
  //     setBestSalesRank_300_(e.target.checked);
  // }

  // const flipProfitRange20_50 = (e) =>{
  //   setProfitRange20_50(e.target.checked);
  // }

  // const flipProfitRange50_100 = (e) =>{
  //   setProfitRange50_100(e.target.checked);
  // }

  // const flipProfitRange_100_ = (e) =>{
  //   setProfitRange_100_(e.target.checked);
  // }

  // const flipBuyPrice0_30 = (e) => {
  //   setBuyPrice0_30(e.target.checked);
  // }

  // const flipBuyPirce30_70 = (e) => {
  //   setBuyPirce30_70(e.target.checked);
  // }

  // const flipBuyPrice_70_ = (e) =>{
  //   setBuyPrice_70_(e.target.checked);
  // }

  // const flipFastShipping = (e) => {
  //   setFastShipping(e.target.checked);
  // }

  const flipAmazonNotComp = (e) => {
    setAmazonNotComp(e.target.checked);
  };

  const buyBoxOpportunity = (e) => {
    setBuyBoxOpp(e.target.checked);
  };

  const flipBuyPriceLessThan20 = (e) => {
    setBuyPriceLessThan20(e.target.checked);
  };

  const flipOutOfStock = (e) => {
    setOutOfStock(e.target.checked);
  };

  const flipNotOutOfStock = (e) => {
    setNotOutOfStock(e.target.checked);
  };

  const flipAmzCA_BF = (e) => {
    setAmzCA_BF(e.target.checked);
  };

  const flipAmzCOM_BF = (e) => {
    setAmzCOM_BF(e.target.checked);
  };

  const flipWALCA_BF = (e) => {
    setWALCA_BF(e.target.checked);
  };

  const flipWAL_BF = (e) => {
    setWAL_BF(e.target.checked);
  };

  const flipAmzCA_ST = (e) => {
    setAmzCA_ST(e.target.checked);
  };

  const flipAmzCOM_ST = (e) => {
    setAmzCOM_ST(e.target.checked);
  };

  const handleLoadMore = () => {
    dispatch(
      fetchProductsStart({
        amzCA_BF,
        amzCOM_BF,
        WALCA_BF,
        WAL_BF,
        amzCA_ST,
        amzCOM_ST,
        amazonNotComp,
        buyBoxOpp,
        outOfStock,
        notOutOfStock,
        category,
        sortBy,
        buyPriceLessThan20,
        startAfterDoc: queryDoc,
        persistProducts: data,
      })
    );
  };

  if (!Array.isArray(data)) return null;

  return (
    <div>
      <div id="app">
        <div className="app-content">
          <div className="u-s-p-y-90">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-12">
                  <div className="shop-w-master">
                    <h1 className="shop-w-master__heading u-s-m-b-30">
                      <FaFilter className="u-s-m-r-8" />
                      <span>FILTERS</span>
                    </h1>
                    <div className="shop-w-master__sidebar">
                      <div className="u-s-m-b-30">
                        <div className="shop-w shop-w--style">
                          <div className="shop-w__intro-wrap">
                            <h1 className="shop-w__h">CATEGORY</h1>
                            <span
                              className="fa fa-minus shop-w__toggle"
                              data-target="#s-category"
                              data-toggle="collapse"
                            />
                          </div>
                          <div
                            className="shop-w__wrap collapse show"
                            id="s-category"
                          >
                            <ul
                              className="shop-w__category-list gl-scroll"
                              onChange={radioButtonsListener}
                            >
                              <li className="has-list">
                                <small>
                                  <input
                                    type="radio"
                                    id={1}
                                    name="gender"
                                    defaultValue="All Products"
                                    defaultChecked
                                  />
                                  <label id="bolded" htmlFor={1}>
                                    All Products
                                  </label>
                                </small>
                                <ul
                                  style={{
                                    display: "block",
                                  }}
                                >
                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={2}
                                        name="gender"
                                        defaultValue="Arts, Crafts & Sewing"
                                      />
                                      <label htmlFor={2}>
                                        Arts, Crafts & Sewing
                                      </label>
                                    </small>
                                  </li>
                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={3}
                                        name="gender"
                                        defaultValue="Clothing, Shoes & Jewelry"
                                      />
                                      <label htmlFor={3}>
                                        Clothing, Shoes & Jewelry
                                      </label>
                                    </small>
                                  </li>
                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={4}
                                        name="gender"
                                        defaultValue="Unique Finds"
                                      />
                                      <label htmlFor={4}>Unique Finds</label>
                                    </small>
                                  </li>
                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={5}
                                        name="gender"
                                        defaultValue="Books"
                                      />
                                      <label htmlFor={5}>Books</label>
                                    </small>
                                  </li>
                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={6}
                                        name="gender"
                                        defaultValue="Health & Household"
                                      />
                                      <label htmlFor={6}>
                                        Health & Household
                                      </label>
                                    </small>
                                  </li>
                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={7}
                                        name="gender"
                                        defaultValue="Pet Supplies"
                                      />
                                      <label htmlFor={7}>Pet Supplies</label>
                                    </small>
                                  </li>
                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={8}
                                        name="gender"
                                        defaultValue="Tools & Home Improvement"
                                      />
                                      <label htmlFor={8}>
                                        Tools & Home Improvement
                                      </label>
                                    </small>
                                  </li>
                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={9}
                                        name="gender"
                                        defaultValue="Appliances"
                                      />
                                      <label htmlFor={9}>Appliances</label>
                                    </small>
                                  </li>
                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={10}
                                        name="gender"
                                        defaultValue="Beauty & Personal Care"
                                      />
                                      <label htmlFor={10}>
                                        Beauty & Personal Care
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={11}
                                        name="gender"
                                        defaultValue="Office Products"
                                      />
                                      <label htmlFor={11}>
                                        Office Products
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={12}
                                        name="gender"
                                        defaultValue="Grocery & Gourmet Food"
                                      />
                                      <label htmlFor={12}>
                                        Grocery & Gourmet Food
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={13}
                                        name="gender"
                                        defaultValue="Patio, Lawn & Garden"
                                      />
                                      <label htmlFor={13}>
                                        Patio, Lawn & Garden
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={14}
                                        name="gender"
                                        defaultValue="Toys & Games"
                                      />
                                      <label htmlFor={14}>Toys & Games</label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={15}
                                        name="gender"
                                        defaultValue="Electronics"
                                      />
                                      <label htmlFor={15}>Electronics</label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={16}
                                        name="gender"
                                        defaultValue="Video Games"
                                      />
                                      <label htmlFor={16}>Video Games</label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={17}
                                        name="gender"
                                        defaultValue="Industrial & Scientific"
                                      />
                                      <label htmlFor={17}>
                                        Industrial & Scientific
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={18}
                                        name="gender"
                                        defaultValue="Automotive"
                                      />
                                      <label htmlFor={18}>Automotive</label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={19}
                                        name="gender"
                                        defaultValue="Home & Kitchen"
                                      />
                                      <label htmlFor={19}>Home & Kitchen</label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={20}
                                        name="gender"
                                        defaultValue="Baby"
                                      />
                                      <label htmlFor={20}>Baby</label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={21}
                                        name="gender"
                                        defaultValue="Kitchen & Dining"
                                      />
                                      <label htmlFor={21}>
                                        Kitchen & Dining
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={22}
                                        name="gender"
                                        defaultValue="Computers & Accessories"
                                      />
                                      <label htmlFor={22}>
                                        Computers & Accessories
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={23}
                                        name="gender"
                                        defaultValue="Sports & Outdoors"
                                      />
                                      <label htmlFor={23}>
                                        Sports & Outdoors
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={24}
                                        name="gender"
                                        defaultValue="CDs & Vinyl"
                                      />
                                      <label htmlFor={24}>CDs & Vinyl</label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={25}
                                        name="gender"
                                        defaultValue="Musical Instruments"
                                      />
                                      <label htmlFor={25}>
                                        Musical Instruments
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={26}
                                        name="gender"
                                        defaultValue="Cell Phones & Accessories"
                                      />
                                      <label htmlFor={26}>
                                        Cell Phones & Accessories
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={27}
                                        name="gender"
                                        defaultValue="Movies & TV"
                                      />
                                      <label htmlFor={27}>Movies & TV</label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={28}
                                        name="gender"
                                        defaultValue="Climate Pledge Friendly"
                                      />
                                      <label htmlFor={28}>
                                        Climate Pledge Friendly
                                      </label>
                                    </small>
                                  </li>

                                  <li className="has-list">
                                    <small>
                                      <input
                                        type="radio"
                                        id={29}
                                        name="gender"
                                        defaultValue="Amazon Renewed"
                                      />
                                      <label htmlFor={29}>Amazon Renewed</label>
                                    </small>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="u-s-m-b-30" id="buy_from">
                        <div className="shop-w shop-w--style">
                          <div className="shop-w__intro-wrap">
                            <h1 className="shop-w__h">BUY FROM</h1>
                            <span
                              className="fa fa-minus shop-w__toggle"
                              data-target="#s-shipping"
                              data-toggle="collapse"
                            />
                          </div>
                          <div
                            className="shop-w__wrap collapse show"
                            id="s-shipping"
                          >
                            <ul className="shop-w__list gl-scroll">
                              {/* <li>
                                <div className="check-box">
                                  <input
                                    onChange={flipAmzCA_BF}
                                    type="checkbox"
                                    id="BF_AMAZON_CA"
                                  />
                                  <div className="check-box__state check-box__state--primary">
                                    <label
                                      className="check-box__label"
                                      htmlFor="BF_AMAZON_CA"
                                    >
                                      Amazon CA
                                    </label>
                                  </div>
                                </div>
                              </li> */}
                              {/* <li>
                                <div className="check-box">
                                  <input
                                    onChange={flipAmzCOM_BF}
                                    type="checkbox"
                                    id="BF_AMAZON_US"
                                  />
                                  <div className="check-box__state check-box__state--primary">
                                    <label
                                      className="check-box__label"
                                      htmlFor="BF_AMAZON_US"
                                    >
                                      Amazon US
                                    </label>
                                  </div>
                                </div>
                              </li> */}
                              <li>
                                <div className="check-box">
                                  <input
                                    onChange={flipWALCA_BF}
                                    type="checkbox"
                                    id="BF_WALMART_CA"
                                  />
                                  <div className="check-box__state check-box__state--primary">
                                    <label
                                      className="check-box__label"
                                      htmlFor="BF_WALMART_CA"
                                    >
                                      Walmart CA
                                    </label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="check-box">
                                  <input
                                    onChange={flipWAL_BF}
                                    type="checkbox"
                                    id="BF_WALMART_US"
                                  />
                                  <div className="check-box__state check-box__state--primary">
                                    <label
                                      className="check-box__label"
                                      htmlFor="BF_WALMART_US"
                                    >
                                      Walmart US
                                    </label>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="u-s-m-b-30" id="sold_to">
                        <div className="shop-w shop-w--style">
                          <div className="shop-w__intro-wrap">
                            <h1 className="shop-w__h">SELL TO</h1>
                            <span
                              className="fa fa-minus shop-w__toggle"
                              data-target="#s-shipping"
                              data-toggle="collapse"
                            />
                          </div>
                          <div
                            className="shop-w__wrap collapse show"
                            id="s-shipping"
                          >
                            <ul className="shop-w__list gl-scroll">
                              <li>
                                <div className="check-box">
                                  <input
                                    onChange={flipAmzCA_ST}
                                    type="checkbox"
                                    id="ST_AMAZON_CA"
                                  />
                                  <div className="check-box__state check-box__state--primary">
                                    <label
                                      className="check-box__label"
                                      htmlFor="ST_AMAZON_CA"
                                    >
                                      Amazon CA
                                    </label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="check-box">
                                  <input
                                    onChange={flipAmzCOM_ST}
                                    type="checkbox"
                                    id="ST_AMAZON_US"
                                  />
                                  <div className="check-box__state check-box__state--primary">
                                    <label
                                      className="check-box__label"
                                      htmlFor="ST_AMAZON_US"
                                    >
                                      Amazon US
                                    </label>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="u-s-m-b-30" id="customizations">
                        <div className="shop-w shop-w--style">
                          <div className="shop-w__intro-wrap">
                            <h1 className="shop-w__h">STOCK CONDITION</h1>
                            <span
                              className="fa fa-minus shop-w__toggle"
                              data-target="#s-shipping"
                              data-toggle="collapse"
                            />
                          </div>
                          <div
                            className="shop-w__wrap collapse show"
                            id="s-shipping"
                          >
                            <ul className="shop-w__list gl-scroll">
                              <li>
                                <div className="check-box">
                                  <input
                                    onChange={flipNotOutOfStock}
                                    type="checkbox"
                                    id="AMAZON_NOT_SELLER"
                                  />
                                  <div className="check-box__state check-box__state--primary">
                                    <label
                                      className="check-box__label"
                                      htmlFor="AMAZON_NOT_SELLER"
                                    >
                                      Not Out of Stock Products
                                    </label>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="check-box">
                                  <input
                                    onChange={flipOutOfStock}
                                    type="checkbox"
                                    id="OUT_OF_STOCK"
                                  />
                                  <div className="check-box__state check-box__state--primary">
                                    <label
                                      className="check-box__label"
                                      htmlFor="OUT_OF_STOCK"
                                    >
                                      Out of Stock Products
                                    </label>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-12">
                  <div className="shop-p">
                    <div className="shop-p__toolbar u-s-m-b-30">
                      <div className="shop-p__tool-style">
                        <div className="tool-style__group u-s-m-b-8">
                          {/* <span className="js-shop-grid-target is-active">Grid</span>
                      <span className="js-shop-list-target">List</span> */}
                        </div>
                        <form>
                          <div className="tool-style__form-wrap">
                            {/* <div className="u-s-m-b-8"><select className="select-box select-box--transparent-b-2">
                              <option>Show: 8</option>
                              <option selected>Show: 12</option>
                              <option>Show: 16</option>
                              <option>Show: 28</option>
                            </select></div> */}
                            {/* <div className="u-s-m-b-8">
                            <div className="check-box">
                              <input  onChange={flipFastShipping} type="checkbox" id="FREE-SHIPPING" />
                              <div className="check-box__state check-box__state--primary">
                                <label className="check-box__label" htmlFor="FREE-SHIPPING">Fast/Free Shipping (Prime)</label></div>
                            </div>
                            </div> */}
                            <div className="u-s-m-b-8">
                              <div className="check-box">
                                <input
                                  onChange={buyBoxOpportunity}
                                  type="checkbox"
                                  id="BUY_BOX_OPPORTUNITY"
                                />
                                <div className="check-box__state check-box__state--primary">
                                  <label
                                    className="check-box__label"
                                    htmlFor="BUY_BOX_OPPORTUNITY"
                                  >
                                    Buy Box Opportunity
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="u-s-m-b-8">
                              <div className="check-box">
                                <input
                                  onChange={flipAmazonNotComp}
                                  type="checkbox"
                                  id="AMAZON_NOT_SELLER"
                                />
                                <div className="check-box__state check-box__state--primary">
                                  <label
                                    className="check-box__label"
                                    htmlFor="AMAZON_NOT_SELLER"
                                  >
                                    Amazon Not Competitor
                                  </label>
                                </div>
                              </div>
                            </div>

                            <div className="u-s-m-b-8">
                              <div className="check-box">
                                <input
                                  onChange={flipBuyPriceLessThan20}
                                  type="checkbox"
                                  id="BUY_PRICE"
                                />
                                <div className="check-box__state check-box__state--primary">
                                  <label
                                    className="check-box__label"
                                    htmlFor="BUY_PRICE"
                                  >
                                    Buy product price less than $20
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="u-s-m-b-8">
                              <select
                                onChange={sortByListener}
                                className="select-box select-box--transparent-b-2"
                              >
                                <option selected>Sort By: Newest Items</option>
                                {/* <option>Sort By: Latest Items</option> */}
                                <option>Sort By: Highest Unit Sales</option>
                                <option>Sort By: Best Sellers Rank</option>
                                <option>Sort By: Highest Profit</option>
                                <option>Sort By: Highest Profit Ratio</option>
                                <option>Sort By: Lowest Buy Price</option>
                              </select>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div className="shop-p__collection">
                      <div className="row is-list-active">
                        {data.length < 1 && (
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <h7 id="iconic_span2">
                              No search results found. Please try updating
                              filter conditions.{" "}
                            </h7>
                          </div>
                        )}

                        {data.map((product, index) => {
                          const {
                            ASIN,
                            productThumbnail,
                            buyAmazonUS,
                            buyWalmartCA,
                            buyWalmartUS,
                            buyAmazonCA,
                            buyEbayUS,
                            sellLink,
                            sellMarket,
                            sellRank,
                            sellPrice,
                            name,
                            category,
                            profitRatio,
                            profit,
                            lastUpdated,
                            unitSales,
                            numOfSellers,
                            numOfItems,
                            walmartNumOfItems,
                            fbaFee,
                            lastSoldTime,
                            referralFeePercent,
                            averageRank30,
                            averageRank90,
                            averageRank180,
                            listPrice,
                            documentID,
                          } = product;

                          return (
                            <div
                              key={documentID}
                              className="col-lg-4 col-md-6 col-sm-6"
                            >
                              <div key={documentID} className="product-m">
                                <div className="product-m__thumb">
                                  <div className="aspect aspect--bg-grey aspect--square u-d-block">
                                    <ProductImage
                                      imageLink={productThumbnail}
                                    />
                                  </div>

                                  <div className="product-m__add-cart">
                                    <span
                                      style={{ borderRadius: "10px" }}
                                      onClick={() => handleAddToCart(product)}
                                      className="btn--e-brand"
                                      data-modal="modal"
                                      data-modal-id="#add-to-cart"
                                    >
                                      Add to Favorites
                                    </span>
                                  </div>
                                </div>
                                <div className="product-m__content">
                                  <div className="product-m__category">
                                    <span href="shop-side-version-2.html">
                                      {category}
                                    </span>
                                  </div>
                                  <div className="product-m__name">
                                    <span href="product-detail.html">
                                      {name}
                                    </span>
                                  </div>

                                  <div className="product-m__rating gl-rating-style">
                                    {profit !== 0 && (
                                      <div>
                                        +${profit}
                                        <span className="product-m__review">
                                          (ROI : {profitRatio}% )
                                        </span>
                                      </div>
                                    )}
                                  </div>

                                  <div className="product-m__hover">
                                    <div className="product-m__preview-description">
                                      <div id="social-platforms">
                                        <div
                                          id="mobile-version"
                                          className="row no-gutters"
                                        >
                                          <div className="left-side">
                                            <h7 id="iconic_span2">
                                              Marketplace (s) to Buy
                                            </h7>
                                            {buyAmazonUS &&
                                              ((!amzCA_BF &&
                                                !amzCOM_BF &&
                                                !WAL_BF &&
                                                !EBAY_BF &&
                                                !WALCA_BF) ||
                                                (amzCA_BF &&
                                                  amzCOM_BF &&
                                                  WAL_BF &&
                                                  EBAY_BF &&
                                                  WALCA_BF) ||
                                                amzCOM_BF) && (
                                                <a
                                                  className="btn btn-icon btn-biz"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={buyAmazonUS.link}
                                                >
                                                  <span id="iconic_span">
                                                    ${buyAmazonUS.price} in
                                                    Amazon US
                                                  </span>
                                                </a>
                                              )}
                                            {buyWalmartUS &&
                                              ((!amzCA_BF &&
                                                !amzCOM_BF &&
                                                !WAL_BF &&
                                                !EBAY_BF &&
                                                !WALCA_BF) ||
                                                (amzCA_BF &&
                                                  amzCOM_BF &&
                                                  WAL_BF &&
                                                  EBAY_BF &&
                                                  WALCA_BF) ||
                                                WAL_BF) && (
                                                <a
                                                  className="btn btn-icon btn-biz"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={buyWalmartUS.link}
                                                >
                                                  <span id="iconic_span">
                                                    ${buyWalmartUS.price} in
                                                    Walmart US
                                                  </span>
                                                </a>
                                              )}
                                            {buyWalmartCA &&
                                              ((!amzCA_BF &&
                                                !amzCOM_BF &&
                                                !WAL_BF &&
                                                !EBAY_BF &&
                                                !WALCA_BF) ||
                                                (amzCA_BF &&
                                                  amzCOM_BF &&
                                                  WAL_BF &&
                                                  EBAY_BF &&
                                                  WALCA_BF) ||
                                                WALCA_BF) && (
                                                <a
                                                  className="btn btn-icon btn-biz"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={buyWalmartCA.link}
                                                >
                                                  <span id="iconic_span">
                                                    ${buyWalmartCA.price} in
                                                    Walmart CA
                                                  </span>
                                                </a>
                                              )}
                                            {buyAmazonCA &&
                                              ((!amzCA_BF &&
                                                !amzCOM_BF &&
                                                !WAL_BF &&
                                                !EBAY_BF &&
                                                !WALCA_BF) ||
                                                (amzCA_BF &&
                                                  amzCOM_BF &&
                                                  WAL_BF &&
                                                  EBAY_BF &&
                                                  WALCA_BF) ||
                                                amzCA_BF) && (
                                                <a
                                                  className="btn btn-icon btn-biz"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={buyAmazonCA.link}
                                                >
                                                  <span id="iconic_span">
                                                    ${buyAmazonCA.price} in
                                                    Amazon CA
                                                  </span>
                                                </a>
                                              )}
                                            {buyEbayUS &&
                                              ((!amzCA_BF &&
                                                !amzCOM_BF &&
                                                !WAL_BF &&
                                                !EBAY_BF &&
                                                !WALCA_BF) ||
                                                (amzCA_BF &&
                                                  amzCOM_BF &&
                                                  WAL_BF &&
                                                  EBAY_BF &&
                                                  WALCA_BF) ||
                                                EBAY_BF) && (
                                                <a
                                                  className="btn btn-icon btn-biz"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={buyEbayUS.link}
                                                >
                                                  <span id="iconic_span">
                                                    ${buyEbayUS.price} in Ebay
                                                    US
                                                  </span>
                                                </a>
                                              )}
                                          </div>
                                          <div className="right-side">
                                            <h7 id="iconic_span2">
                                              Marketplace to Sell
                                            </h7>
                                            <a
                                              className="btn btn-icon btn-biz"
                                              rel="noreferrer"
                                              target="_blank"
                                              href={sellLink}
                                            >
                                              {sellPrice ? (
                                                <span id="iconic_span">
                                                  ${sellPrice} in {sellMarket}
                                                </span>
                                              ) : (
                                                <span id="iconic_span">
                                                  Out of Stock in {sellMarket}
                                                </span>
                                              )}
                                            </a>
                                            <div className="listed_features">
                                              {numOfItems > 0 ? (
                                                <p className="product_info">
                                                  Pack of{" "}
                                                  <strong>{numOfItems}</strong>{" "}
                                                </p>
                                              ) : (
                                                <p className="product_info">
                                                  Pack of <strong>1</strong>{" "}
                                                </p>
                                              )}

                                              <p className="product_info">
                                                Best Seller Rank:{" "}
                                                <strong>#{sellRank}</strong>{" "}
                                              </p>
                                              {unitSales > 0 ? (
                                                <p className="product_info">
                                                  Unit Sales per Month:{" "}
                                                  <strong>{unitSales}</strong>{" "}
                                                </p>
                                              ) : (
                                                <p className="product_info">
                                                  Unit Sales per Month:{" "}
                                                  <strong>unknown</strong>{" "}
                                                </p>
                                              )}

                                              <p className="product_info">
                                                Number of Sellers:{" "}
                                                <strong>{numOfSellers}</strong>{" "}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          id="normal-version"
                                          className="row no-gutters"
                                        >
                                          <div className="col">
                                            <div className="left-side">
                                              <h7 id="iconic_span2">
                                                Marketplace to Buy
                                              </h7>
                                              {buyAmazonUS &&
                                                ((!amzCA_BF &&
                                                  !amzCOM_BF &&
                                                  !WAL_BF &&
                                                  !EBAY_BF &&
                                                  !WALCA_BF) ||
                                                  (amzCA_BF &&
                                                    amzCOM_BF &&
                                                    WAL_BF &&
                                                    EBAY_BF &&
                                                    WALCA_BF) ||
                                                  amzCOM_BF) && (
                                                  <a
                                                    className="btn btn-icon btn-biz"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href={buyAmazonUS.link}
                                                  >
                                                    <span id="iconic_span">
                                                      ${buyAmazonUS.price} in
                                                      Amazon US
                                                    </span>
                                                  </a>
                                                )}
                                              {buyWalmartUS &&
                                                ((!amzCA_BF &&
                                                  !amzCOM_BF &&
                                                  !WAL_BF &&
                                                  !EBAY_BF &&
                                                  !WALCA_BF) ||
                                                  (amzCA_BF &&
                                                    amzCOM_BF &&
                                                    WAL_BF &&
                                                    EBAY_BF &&
                                                    WALCA_BF) ||
                                                  WAL_BF) && (
                                                  <a
                                                    className="btn btn-icon btn-biz"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href={buyWalmartUS.link}
                                                  >
                                                    <span id="iconic_span">
                                                      ${buyWalmartUS.price} in
                                                      Walmart US
                                                    </span>
                                                  </a>
                                                )}
                                              {buyWalmartCA &&
                                                ((!amzCA_BF &&
                                                  !amzCOM_BF &&
                                                  !WAL_BF &&
                                                  !EBAY_BF &&
                                                  !WALCA_BF) ||
                                                  (amzCA_BF &&
                                                    amzCOM_BF &&
                                                    WAL_BF &&
                                                    EBAY_BF &&
                                                    WALCA_BF) ||
                                                  WALCA_BF) && (
                                                  <a
                                                    className="btn btn-icon btn-biz"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href={buyWalmartCA.link}
                                                  >
                                                    <span id="iconic_span">
                                                      ${buyWalmartCA.price} in
                                                      Walmart CA
                                                    </span>
                                                  </a>
                                                )}
                                              {buyAmazonCA &&
                                                ((!amzCA_BF &&
                                                  !amzCOM_BF &&
                                                  !WAL_BF &&
                                                  !EBAY_BF &&
                                                  !WALCA_BF) ||
                                                  (amzCA_BF &&
                                                    amzCOM_BF &&
                                                    WAL_BF &&
                                                    EBAY_BF &&
                                                    WALCA_BF) ||
                                                  amzCA_BF) && (
                                                  <a
                                                    className="btn btn-icon btn-biz"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href={buyAmazonCA.link}
                                                  >
                                                    <span id="iconic_span">
                                                      ${buyAmazonCA.price} in
                                                      Amazon CA
                                                    </span>
                                                  </a>
                                                )}
                                              {buyEbayUS &&
                                                ((!amzCA_BF &&
                                                  !amzCOM_BF &&
                                                  !WAL_BF &&
                                                  !EBAY_BF &&
                                                  !WALCA_BF) ||
                                                  (amzCA_BF &&
                                                    amzCOM_BF &&
                                                    WAL_BF &&
                                                    EBAY_BF &&
                                                    WALCA_BF) ||
                                                  EBAY_BF) && (
                                                  <a
                                                    className="btn btn-icon btn-biz"
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    href={buyEbayUS.link}
                                                  >
                                                    <span id="iconic_span">
                                                      ${buyEbayUS.price} in Ebay
                                                      US
                                                    </span>
                                                  </a>
                                                )}
                                              {buyWalmartUS &&
                                                ((!amzCA_BF &&
                                                  !amzCOM_BF &&
                                                  !WAL_BF &&
                                                  !EBAY_BF &&
                                                  !WALCA_BF) ||
                                                  (amzCA_BF &&
                                                    amzCOM_BF &&
                                                    WAL_BF &&
                                                    EBAY_BF &&
                                                    WALCA_BF) ||
                                                  WAL_BF) && (
                                                  <div className="listed_features">
                                                    {walmartNumOfItems && (
                                                      <p className="product_info">
                                                        Pack of{" "}
                                                        <strong>
                                                          {walmartNumOfItems}
                                                        </strong>{" "}
                                                      </p>
                                                    )}

                                                    <p className="product_info">
                                                      Estimated Tax:{" "}
                                                      <strong>
                                                        $
                                                        {(
                                                          buyWalmartUS.price /
                                                          10
                                                        ).toFixed(2)}
                                                      </strong>{" "}
                                                    </p>
                                                  </div>
                                                )}

                                              {buyWalmartCA &&
                                                ((!amzCA_BF &&
                                                  !amzCOM_BF &&
                                                  !WAL_BF &&
                                                  !EBAY_BF &&
                                                  !WALCA_BF) ||
                                                  (amzCA_BF &&
                                                    amzCOM_BF &&
                                                    WAL_BF &&
                                                    EBAY_BF &&
                                                    WALCA_BF) ||
                                                  WALCA_BF) && (
                                                  <div className="listed_features">
                                                    <p className="product_info">
                                                      Estimated Tax:{" "}
                                                      <strong>
                                                        $
                                                        {(
                                                          buyWalmartCA.price /
                                                          10
                                                        ).toFixed(2)}
                                                      </strong>{" "}
                                                    </p>
                                                  </div>
                                                )}
                                              <div style={{ padding: "10px" }}>
                                                <ProductButtons
                                                  ASIN={ASIN}
                                                  buyWalmartCA={buyWalmartCA}
                                                  buyWalmartUS={buyWalmartUS}
                                                  name={name}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col">
                                            <div className="right-side">
                                              <h7 id="iconic_span2">
                                                Marketplace to Sell
                                              </h7>
                                              <a
                                                className="btn btn-icon btn-biz"
                                                rel="noreferrer"
                                                target="_blank"
                                                href={sellLink}
                                              >
                                                {sellPrice ? (
                                                  <span id="iconic_span">
                                                    ${sellPrice} in {sellMarket}
                                                  </span>
                                                ) : (
                                                  <span id="iconic_span">
                                                    Out of Stock in {sellMarket}
                                                  </span>
                                                )}
                                              </a>
                                              <div className="listed_features">
                                                {numOfItems > 0 ? (
                                                  <p className="product_info">
                                                    Pack of{" "}
                                                    <strong>
                                                      {numOfItems}
                                                    </strong>{" "}
                                                  </p>
                                                ) : (
                                                  <p className="product_info">
                                                    Pack of <strong>1</strong>{" "}
                                                  </p>
                                                )}
                                                <p className="product_info">
                                                  Best Sellers Rank:{" "}
                                                  <strong>#{sellRank}</strong>{" "}
                                                </p>
                                                {unitSales > 0 ? (
                                                  <p className="product_info">
                                                    Unit Sales per Month:{" "}
                                                    <strong>{unitSales}</strong>{" "}
                                                  </p>
                                                ) : (
                                                  <p className="product_info">
                                                    Unit Sales per Month:{" "}
                                                    <strong>unknown</strong>{" "}
                                                  </p>
                                                )}

                                                <p className="product_info">
                                                  Number of Sellers:{" "}
                                                  <strong>
                                                    {numOfSellers}
                                                  </strong>{" "}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="product-m__wishlist">
                                      <p>Last Updated: {lastUpdated}</p>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    width: "100%",
                                    marginTop: "20px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <ProductDetails
                                    currentRank={sellRank}
                                    profit={profit}
                                    ROI={profitRatio}
                                    FBAFee={fbaFee}
                                    unitSales={unitSales}
                                    lastSoldTime={lastSoldTime}
                                    referralFeePercent={referralFeePercent}
                                    averageRank30={averageRank30}
                                    averageRank90={averageRank90}
                                    averageRank180={averageRank180}
                                    listPrice={listPrice}
                                    packof={numOfItems}
                                    buyWalmartUS={buyWalmartUS}
                                    buyWalmartCA={buyWalmartCA}
                                    sellPrice={sellPrice}
                                    walmartNumOfItems={walmartNumOfItems}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="u-s-p-y-90">
                      <ul className="shop-p__pagination">
                        <div className="tool-style__group u-s-m-b-8">
                          {!isLastPage && (
                            <span
                              onClick={() => handleLoadMore()}
                              className="js-shop-list-target"
                            >
                              LOAD MORE
                            </span>
                          )}
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <PopUp setTrigger={setButtonPopup} trigger={buttonPopup}>
          <h3>My popup</h3>
        </PopUp>

        {/* <div className="modal fade" id="quick-look">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content modal--shadow">
          <button className="btn dismiss-button fas fa-times" type="button" data-dismiss="modal" />
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-5">
                <div className="pd-breadcrumb u-s-m-b-30">
                  <ul className="pd-breadcrumb__list">
                    <li className="has-separator">
                      <a href="index.hml">Home</a></li>
                    <li className="has-separator">
                      <a href="shop-side-version-2.html">Electronics</a></li>
                    <li className="has-separator">
                      <a href="shop-side-version-2.html">DSLR Cameras</a></li>
                    <li className="is-marked">
                      <a href="shop-side-version-2.html">Nikon Cameras</a></li>
                  </ul>
                </div>
                <div className="pd u-s-m-b-30">
                  <div className="pd-wrap">
                    <div id="js-product-detail-modal">
                      <div>
                        <img className="u-img-fluid" src="images/product/product-d-1.jpg" alt /></div>
                      <div>
                        <img className="u-img-fluid" src="images/product/product-d-2.jpg" alt /></div>
                      <div>
                        <img className="u-img-fluid" src="images/product/product-d-3.jpg" alt /></div>
                      <div>
                        <img className="u-img-fluid" src="images/product/product-d-4.jpg" alt /></div>
                      <div>
                        <img className="u-img-fluid" src="images/product/product-d-5.jpg" alt /></div>
                    </div>
                  </div>
                  <div className="u-s-m-t-15">
                    <div id="js-product-detail-modal-thumbnail">
                      <div>
                        <img className="u-img-fluid" src="images/product/product-d-1.jpg" alt /></div>
                      <div>
                        <img className="u-img-fluid" src="images/product/product-d-2.jpg" alt /></div>
                      <div>
                        <img className="u-img-fluid" src="images/product/product-d-3.jpg" alt /></div>
                      <div>
                        <img className="u-img-fluid" src="images/product/product-d-4.jpg" alt /></div>
                      <div>
                        <img className="u-img-fluid" src="images/product/product-d-5.jpg" alt /></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="pd-detail">
                  <div>
                    <span className="pd-detail__name">Nikon Camera 4k Lens Zoom Pro</span></div>
                  <div>
                    <div className="pd-detail__inline">
                      <span className="pd-detail__price">$6.99</span>
                      <span className="pd-detail__discount">(76% OFF)</span><del className="pd-detail__del">$28.97</del></div>
                  </div>
                  <div className="u-s-m-b-15">
                    <div className="pd-detail__rating gl-rating-style"><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star" /><i className="fas fa-star-half-alt" />
                      <span className="pd-detail__review u-s-m-l-4">
                        <a href="product-detail.html">23 Reviews</a></span></div>
                  </div>
                  <div className="u-s-m-b-15">
                    <div className="pd-detail__inline">
                      <span className="pd-detail__stock">200 in stock</span>
                      <span className="pd-detail__left">Only 2 left</span></div>
                  </div>
                  <div className="u-s-m-b-15">
                    <span className="pd-detail__preview-desc">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span></div>
                  <div className="u-s-m-b-15">
                    <div className="pd-detail__inline">
                      <span className="pd-detail__click-wrap"><i className="far fa-heart u-s-m-r-6" />
                        <a href="signin.html">Add to Wishlist</a>
                        <span className="pd-detail__click-count">(222)</span></span></div>
                  </div>
                  <div className="u-s-m-b-15">
                    <div className="pd-detail__inline">
                      <span className="pd-detail__click-wrap"><i className="far fa-envelope u-s-m-r-6" />
                        <a href="signin.html">Email me When the price drops</a>
                        <span className="pd-detail__click-count">(20)</span></span></div>
                  </div>
                  <div className="u-s-m-b-15">
                    <ul className="pd-social-list">
                      <li>
                        <a className="s-fb--color-hover" href="#"><i className="fab fa-facebook-f" /></a></li>
                      <li>
                        <a className="s-tw--color-hover" href="#"><i className="fab fa-twitter" /></a></li>
                      <li>
                        <a className="s-insta--color-hover" href="#"><i className="fab fa-instagram" /></a></li>
                      <li>
                        <a className="s-wa--color-hover" href="#"><i className="fab fa-whatsapp" /></a></li>
                      <li>
                        <a className="s-gplus--color-hover" href="#"><i className="fab fa-google-plus-g" /></a></li>
                    </ul>
                  </div>
                  <div className="u-s-m-b-15">
                    <form className="pd-detail__form">
                      <div className="pd-detail-inline-2">
                        <div className="u-s-m-b-15">
                          <div className="input-counter">
                            <span className="input-counter__minus fas fa-minus" />
                            <input className="input-counter__text input-counter--text-primary-style" type="text" defaultValue={1} data-min={1} data-max={1000} />
                            <span className="input-counter__plus fas fa-plus" /></div>
                        </div>
                        <div className="u-s-m-b-15">
                          <button className="btn btn--e-brand-b-2" type="submit">Add to Cart</button></div>
                      </div>
                    </form>
                  </div>
                  <div className="u-s-m-b-15">
                    <span className="pd-detail__label u-s-m-b-8">Product Policy:</span>
                    <ul className="pd-detail__policy-list">
                      <li><i className="fas fa-check-circle u-s-m-r-8" />
                        <span>Buyer Protection.</span></li>
                      <li><i className="fas fa-check-circle u-s-m-r-8" />
                        <span>Full Refund if you don't receive your order.</span></li>
                      <li><i className="fas fa-check-circle u-s-m-r-8" />
                        <span>Returns accepted if product not as described.</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> */}
      </div>
      <noscript>
        <div className="app-setting">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="app-setting__wrap">
                  <h1 className="app-setting__h1">
                    JavaScript is disabled in your browser.
                  </h1>
                  <span className="app-setting__text">
                    Please enable JavaScript in your browser or upgrade to a
                    JavaScript-capable browser.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </noscript>
    </div>
  );
};

export default Development;
