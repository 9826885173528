import React from "react";
import { Link } from "react-router-dom";
import "./styless.css";

const Cancel = () => {
  return (
    <section className="container price-font">
      <div style={{ marginTop: "100px" }} className="cardContainer">
        <div className="card">
          <div className="cardTop">
            <h1 id="price">Sorry, payment failed</h1>
          </div>
          <div className="bottomCont">
            <div style={{ margin: "auto" }} className="bottomRight">
              <Link to="/">
                <button>Return to homepage</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cancel;
