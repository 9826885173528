import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  signUpUserStart,
  googleSignInStart,
  userError,
} from "./../../redux/User/user.actions";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../assests/logotry.png";
import { checkout } from "../../Utils";
import emailjs from "@emailjs/browser";
import "./styles.scss";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
  userErr: user.userErr,
});

const Registration = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser, userErr } = useSelector(mapState);

  const [isLoading, setIsLoading] = useState(false);

  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState([]);

  const linkStyle = {
    color: "#5ea3f3", // Change the color to your desired color
    textDecoration: "none",
    fontWeight: "700",
  };

  useEffect(() => {
    if (currentUser) {
      emailjs
        .send(
          "service_ap50n78",
          "template_el4zphd",
          { to_email: currentUser.email, to_name: currentUser.displayName },
          "q4MT5_O8SngGMvQx2"
        )
        .then(
          (result) => {},
          (error) => {
            console.log(error.text);
          }
        );

      setIsLoading(true);
      checkout(currentUser.id);
    }

    return () => {
      setDisplayName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setErrors([]);
      dispatch(userError([]));
    };
  }, [currentUser, dispatch, history]);

  useEffect(() => {
    if (Array.isArray(userErr) && userErr.length > 0) {
      setErrors(userErr);
    }
  }, [userErr]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      signUpUserStart({
        displayName,
        email,
        password,
        confirmPassword,
      })
    );
  };

  const handleGoogleSignIn = () => {
    dispatch(googleSignInStart());
  };

  return (
    <div className="signin">
      {isLoading ? (
        <div className="spinner-container">
          <svg
            className="spinner"
            width="65px"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              stroke-width="6"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      ) : (
        <div className="wrap">
          <div className="sign-up">
            <h1>
              <Link to="/">
                <img style={{ width: "100%" }} src={Logo} alt="logo" />
              </Link>
            </h1>

            {errors.length > 0 && (
              <ul>
                {errors.map((err, index) => {
                  return (
                    <li style={{ color: "red", width: "300px" }} key={index}>
                      {err}
                    </li>
                  );
                })}
              </ul>
            )}

            <input
              onClick={handleGoogleSignIn}
              className="login_button"
              type="submit"
              value="Login with Google"
            />
            <div className="divider">
              <b>OR</b>
            </div>
            <form onSubmit={handleSubmit}>
              <input
                placeholder="Email Address"
                type="text"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
              />

              <input
                placeholder="Full Name"
                type="text"
                value={displayName}
                name="displayName"
                onChange={(e) => setDisplayName(e.target.value)}
              />

              <input
                placeholder="Password"
                type="password"
                value={password}
                name="password"
                onChange={(e) => setPassword(e.target.value)}
              />

              <input
                placeholder="Confirm Password"
                type="password"
                value={confirmPassword}
                name="confirmPassword"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <input
                className="login_button addPadding"
                type="submit"
                value="Sign up"
              />
              <br />
            </form>
            <small>
              By signing up, you agree to our{" "}
              <Link to="/terms-and-conditions">
                <a style={linkStyle}>Terms</a>
              </Link>{" "}
              &{" "}
              <Link to="/privacy-policy">
                <a style={linkStyle}>Data Policy</a>
              </Link>
              .
            </small>
          </div>

          <div className="infobox">
            <p>
              Have an account? <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Registration;
