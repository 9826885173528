export const firebaseConfig = {
  apiKey: "AIzaSyAlw2USuhGWCsRMnD1aGBI6DZvkwv2iCvE",
  authDomain: "arbithub.com",
  databaseURL: "https://clockwork-9ed2c-default-rtdb.firebaseio.com",
  projectId: "clockwork-9ed2c",
  storageBucket: "clockwork-9ed2c.appspot.com",
  messagingSenderId: "766352857283",
  appId: "1:766352857283:web:2bf674fd555c9eed7eaa76",
  measurementId: "G-MHHW6ER8K1",
};
