import React from "react";
import Paragraph from "../Paragraph";

function PolicyDetails({ text }) {
  return (
    <div>
      <section className="container price-font">
        <header className="pricing-header">
          <h1>{text}</h1>
          <p>Our Commitment to Your Privacy</p>
        </header>
        <div
          style={{ width: "100%", maxWidth: "100%", maxHeight: "100%" }}
          className="cardContainer"
        >
          <div className="card">
            <div className="cardTop">
              <Paragraph
                header=""
                text="For the purpose of this policy, Arbithub.com will be referred to as ‘Our Website’.
This statement governs our privacy policies with respect to those users of the Site (“Visitors”) who visit without transacting business and Visitors who register to transact business on the Site and make use of the various services offered by ‘Our Website’ (collectively, “Services”) (“Users”).
"
              />
              <Paragraph
                header="Personally Identifiable Information"
                text="Refers to any information that identifies or can be used to identify, contact, or locate the person to whom such information pertains, including, but not limited to, name, address, phone number, fax number, email address, financial profiles, and credit card information. Personally Identifiable Information does not include information that is collected anonymously (that is, without identification of the individual user) or demographic information not connected to an identified individual."
              />
              <Paragraph
                header="What Personally Identifiable Information is collected?"
                text="We may collect basic user profile information from all of our Visitors. We collect the following additional information from our Users: the names, addresses, phone numbers and email addresses of Users, the nature and size of the business, and the nature and size of the advertising inventory that the User intends to purchase or sell."
              />
              <Paragraph
                header="What organizations are collecting the information?"
                text="In addition to our direct collection of information, our third party service vendors (such as credit card companies, clearinghouses and banks) who may provide such services as credit, insurance, and escrow services may collect this information from our Visitors and Users. We do not control how these third parties use such information, but we do ask them to disclose how they use personal information provided to them from Visitors and Users. Some of these third parties may be intermediaries that act solely as links in the distribution chain, and do not store, retain, or use the information given to them."
              />
              <Paragraph
                header="How does the Site use Personally Identifiable Information?"
                text="We use Personally Identifiable Information to customize the Site, to make appropriate service offerings, and to fulfill buying and selling requests on the Site. We may email Visitors and Users about research or purchase and selling opportunities on the Site or information related to the subject matter of the Site. We may also use Personally Identifiable Information to contact Visitors and Authorized Customers in response to specific inquiries, or to provide requested information."
              />
              <Paragraph
                header="With whom may the information be shared?"
                text="Personally Identifiable Information about Users may be shared with other Users who wish to evaluate potential transactions with other Users. We may share aggregated information about our Visitors, including the demographics of our Visitors and Users, with our affiliated agencies and third party vendors. We also offer the opportunity to “opt out” of receiving information or being contacted by us or by any agency acting on our behalf."
              />
              <Paragraph
                header="How is Personally Identifiable Information stored?"
                text="Personally Identifiable Information collected by ‘Our Website’ is securely stored and is not accessible to third parties or employees of ‘Our Website’ except for use as indicated above."
              />
              <Paragraph
                header="What choices are available to Visitors regarding collection, use and distribution of the information?"
                text="Visitors and Users may opt out of receiving unsolicited information from or being contacted by us and/or our vendors and affiliated agencies by responding to emails as instructed, or by contacting us via the Contact Us Page."
              />
              <Paragraph
                header="Are Cookies Used on the Site?"
                text="Cookies are not used on ‘Our Website’."
              />
              <Paragraph
                header="How does 'Our Website' use login information?"
                text="‘Our Website’ uses login information, including, but not limited to, IP addresses and ISPs, to analyze trends, administer the Site, track a user’s movement and use, and gather broad demographic information."
              />
              <Paragraph
                header="What partners or service providers have access to Personally Identifiable Information from Visitors and/or Authorized Customers on the Site?"
                text="‘Our Website’ has entered into and will continue to enter into partnerships and other affiliations with a number of vendors. Such vendors may have access to certain Personally Identifiable Information on a need to know basis for evaluating Users for service eligibility. Our privacy policy does not cover their collection or use of this information. Disclosure of Personally Identifiable Information to comply with law. We will disclose Personally Identifiable Information in order to comply with a court order or subpoena or a request from a law enforcement agency to release information. We will also disclose Personally Identifiable Information when reasonably necessary to protect the safety of our Visitors and Users."
              />
              <Paragraph
                header="How does the Site keep Personally Identifiable Information secure?"
                text="All of our employees are familiar with our security policy and practices. The Personally Identifiable Information of our Visitors and Users is only accessible to a limited number of qualified employees who are given a password in order to gain access to the information. We audit our security systems and processes on a regular basis. Sensitive information such as credit card numbers, is protected by encryption protocols, in place to protect information sent over the Internet. While we take commercially reasonable measures to maintain a secure site, electronic communications and databases are subject to errors, tampering and break-ins, and we cannot guarantee or warrant that such events will not take place and we will not be liable to Visitors or Users for any such occurrences."
              />
              <Paragraph
                header="How can Visitors correct any inaccuracies in Personally Identifiable Information?"
                text="Visitors and Users may contact us to update Personally Identifiable Information about them or to correct any inaccuracies."
              />
              <Paragraph
                header="Can a Visitor delete or deactivate Personally Identifiable Information collected by the Site?"
                text="We provide Visitors and Users with a mechanism to delete/deactivate Personally Identifiable Information from the Site’s database by contacting us. However, because of backups and records of deletions, it may be impossible to delete a Visitor’s entry without retaining some residual information. An individual who requests to have Personally Identifiable Information deactivated will have this information functionally deleted, and we will not sell, transfer, or use Personally Identifiable Information relating to that individual in any way moving forward. To opt out of Google Analytics tracking you should visit: https://tools.google.com/dlpage/gaoptout/."
              />
              <Paragraph
                header="What happens if the Privacy Policy Changes?"
                text="We will let our Visitors and Users know about changes to our privacy policy by posting such changes on the Site. However, if we are changing our privacy policy in a manner that might cause disclosure of Personally Identifiable Information that a Visitor or User has previously requested not be disclosed, we will contact such Visitor or User to allow such Visitor or User to prevent such disclosure."
              />
              <Paragraph
                header="Links"
                text="‘Our Website’ contains links to other web sites. Please note that when you click on one of these links, you are moving to another web site. We encourage you to read the privacy statements of these linked sites as their privacy policies may differ from ours."
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default PolicyDetails;
