import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./default.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { checkUserSession } from "./redux/User/user.actions";

//components
import AdminToolbar from "./components/AdminToolbar";
import VerificationToolbar from "./components/VerificationToolbar";

//hoc
import WithAuth from "./hoc/WithAuth";
import WithAdminAuth from "./hoc/WithAdminAuth";
import WithSubscription from "./hoc/WithSubscription";

//layouts
import HomePageLayout from "./layouts/HomePageLayout";
import EmptyLayout from "./layouts/EmptyLayout";

//pages
import Cancel from "./pages/Cancel";
import Success from "./pages/Success";
import Home from "./pages/Homepage";
import Login from "./pages/Login";
import Registration from "./pages/Registration";
import Recovery from "./pages/Recovery";
import Pricing from "./pages/Pricing";
import Admin from "./pages/Admin";
import Development from "./pages/Development";
import Cart from "./pages/Cart";
import Contact from "./pages/Contact";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ScrollToTop from "./hoc/ScrollToTop";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});

const App = (props) => {
  const { currentUser } = useSelector(mapState);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkUserSession());
  }, [dispatch]);

  return (
    <Router>
      <AdminToolbar />
      <VerificationToolbar />
      <ScrollToTop>
        <Switch>
          <Route
            path={["/", "/home"]}
            exact
            render={() => (
              <HomePageLayout>
                <Home />
              </HomePageLayout>
            )}
          />

          <Route
            path="/terms-and-conditions"
            exact
            render={() => (
              <HomePageLayout>
                <TermsConditions />
              </HomePageLayout>
            )}
          />

          <Route
            path="/privacy-policy"
            exact
            render={() => (
              <HomePageLayout>
                <PrivacyPolicy />
              </HomePageLayout>
            )}
          />

          <Route
            path="/sign-up"
            exact
            render={() =>
              currentUser &&
              currentUser.subscription &&
              currentUser.subscription.planId ? (
                <Redirect to="/" />
              ) : (
                <EmptyLayout>
                  <Registration />
                </EmptyLayout>
              )
            }
          />

          <Route
            path="/login"
            exact
            render={() =>
              currentUser ? (
                <Redirect to="/" />
              ) : (
                <EmptyLayout>
                  <Login />
                </EmptyLayout>
              )
            }
          />

          <Route
            path="/recovery"
            exact
            render={() =>
              currentUser ? (
                <Redirect to="/" />
              ) : (
                <EmptyLayout>
                  <Recovery />
                </EmptyLayout>
              )
            }
          />

          <Route
            path="/success"
            exact
            render={() => (
              <EmptyLayout>
                <Success />
              </EmptyLayout>
            )}
          />

          <Route
            path="/cancel"
            exact
            render={() => (
              <EmptyLayout>
                <Cancel />
              </EmptyLayout>
            )}
          />

          <Route
            path="/products"
            exact
            render={() => (
              <HomePageLayout>
                <WithSubscription>
                  <Development />
                </WithSubscription>
              </HomePageLayout>
            )}
          />

          <Route
            path="/pricing"
            exact
            render={() => (
              <HomePageLayout>
                <Pricing text="Simple, competitive pricing" />
              </HomePageLayout>
            )}
          />

          <Route
            path="/contact-us"
            exact
            render={() => (
              <HomePageLayout>
                <Contact />
              </HomePageLayout>
            )}
          />

          <Route
            path="/favorites"
            exact
            render={() => (
              <WithAuth>
                <HomePageLayout>
                  <Cart />
                </HomePageLayout>
              </WithAuth>
            )}
          />

          <Route
            path="/admin"
            exact
            render={() => (
              <WithAdminAuth>
                <HomePageLayout>
                  <Admin />
                </HomePageLayout>
              </WithAdminAuth>
            )}
          />
        </Switch>
      </ScrollToTop>
    </Router>
  );
};

export default App;
