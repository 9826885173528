import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { firebaseConfig } from "./config";

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();

export const GoogleProvider = new firebase.auth.GoogleAuthProvider();
GoogleProvider.setCustomParameters({ prompt: "select_account" });

export const isUserVerified = async (userAuth) => {
  if (!userAuth) return false;

  try {
    const user = auth.currentUser;
    return user.emailVerified;
  } catch (err) {
    console.log(err);
  }
};

export const sendEmailVerification = async (userAuth) => {
  if (!userAuth) return;

  try {
    const user = auth.currentUser;
    await user.sendEmailVerification();
  } catch (err) {
    console.log(err);
  }
};

export const updateUserProfile = async ({ userAuth, additionalData }) => {
  if (!userAuth) return;

  console.log("updateUserProfile test");

  const { uid } = userAuth;

  const documentation = "users/" + uid;
  const userRef = firestore.doc(documentation);

  try {
    await userRef.update({
      ...additionalData,
    });
  } catch (err) {
    console.log(err);
  }

  return userRef;
};

export const handleUserProfile = async ({ userAuth, additionalData }) => {
  if (!userAuth) return;

  const { uid } = userAuth;

  const documentation = "users/" + uid;
  const userRef = firestore.doc(documentation);
  const snapshot = await userRef.get();

  if (!snapshot.exists) {
    const { displayName, email } = userAuth;
    const timestamp = new Date();
    const userRoles = ["user"];

    try {
      await userRef.set({
        displayName,
        email,
        createdDate: timestamp,
        userRoles: userRoles,
        ...additionalData,
      });
    } catch (err) {
      console.log(err);
    }
  }

  return userRef;
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};
