import { takeLatest, call, all, put } from "redux-saga/effects";
import {
  auth,
  handleUserProfile,
  getCurrentUser,
  GoogleProvider,
  updateUserProfile,
} from "./../../firebase/utils";
import userTypes from "./user.types";
import {
  signInSuccess,
  signOutUserSuccess,
  resetPasswordSuccess,
  userError,
} from "./user.actions";
import { handleResetPasswordAPI } from "./user.helpers";

export function* getSnapshotFromUserAuth(user, additionalData = {}) {
  try {
    const userRef = yield call(handleUserProfile, {
      userAuth: user,
      additionalData,
    });
    const snapshot = yield userRef.get();
    yield put(
      signInSuccess({
        id: snapshot.id,
        ...snapshot.data(),
      })
    );
  } catch (err) {
    console.log(err);
  }
}

export function* emailSignIn({ payload: { email, password } }) {
  if (!email) {
    const err = ["Please enter your email address"];
    yield put(userError(err));

    return;
  }

  if (!password) {
    const err = ["Please enter your password"];
    yield put(userError(err));

    return;
  }

  if (email) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email)) {
      const err = ["Please enter a valid email address"];
      yield put(userError(err));

      return;
    }
  }

  try {
    const { user } = yield auth.signInWithEmailAndPassword(email, password);
    yield getSnapshotFromUserAuth(user);
  } catch (exceptionErr) {
    console.log(exceptionErr);

    const err = [exceptionErr.message];
    yield put(userError(err));
    return;
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(userTypes.EMAIL_SIGN_IN_START, emailSignIn);
}

export function* onHandleSubscriptionUpdateStart() {
  yield takeLatest(
    userTypes.HANDLE_SUBSCRIPTION_UPDATE_START,
    handleSubscriptionUpdate
  );
}

export function* handleSubscriptionUpdate({
  payload: { sessionId, planId, planStartDate, planEndDate, planDuration },
}) {
  console.log("handleSubscriptionUpdate test");
  console.log(sessionId, planId, planStartDate, planEndDate, planDuration);
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;

    const additionalData = {
      subscription: {
        sessionId,
        planId,
        planStartDate,
        planEndDate,
        planDuration,
      },
    };

    const userRef = yield call(updateUserProfile, {
      userAuth,
      additionalData,
    });

    const snapshot = yield userRef.get();
    yield put(
      signInSuccess({
        id: snapshot.id,
        ...snapshot.data(),
      })
    );
    // yield getSnapshotFromUserAuth(userAuth, additionalData);
  } catch (err) {
    console.log(err);
  }
}

export function* isUserAuthenticated() {
  try {
    const userAuth = yield getCurrentUser();
    if (!userAuth) return;

    yield getSnapshotFromUserAuth(userAuth);
  } catch (err) {
    console.log(err);
  }
}

export function* onCheckUserSession() {
  yield takeLatest(userTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* signOutUser() {
  try {
    yield auth.signOut();
    yield put(signOutUserSuccess());
  } catch (err) {
    console.log(err);
  }
}

export function* onSignOutUserStart() {
  yield takeLatest(userTypes.SIGN_OUT_USER_START, signOutUser);
}

export function* signUpUser({
  payload: { displayName, email, password, confirmPassword },
}) {
  if (!email) {
    const err = ["Please enter your email address"];
    yield put(userError(err));

    return;
  }

  if (!displayName) {
    const err = ["Please enter your full name"];
    yield put(userError(err));

    return;
  }

  if (!password) {
    const err = ["Please enter your password"];
    yield put(userError(err));

    return;
  }

  if (!confirmPassword) {
    const err = ["Please enter your confirm password"];
    yield put(userError(err));

    return;
  }

  if (email) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(email)) {
      const err = ["Please enter a valid email address"];
      yield put(userError(err));

      return;
    }
  }

  if (password !== confirmPassword) {
    const err = ["Passwords don't match"];
    yield put(userError(err));
    return;
  }

  if (password.length < 8) {
    const err = [
      "Please enter a valid password",
      "Password should be at least eight characters",
    ];
    yield put(userError(err));
    return;
  }

  if (!password.match(/\d/)) {
    const err = [
      "Please enter a valid password",
      "Password should include at least one number",
    ];
    yield put(userError(err));
    return;
  }

  if (!password.match(/[A-Za-z]/)) {
    const err = [
      "Please enter a valid password",
      "Password should include at least one letter",
    ];
    yield put(userError(err));
    return;
  }

  try {
    const { user } = yield auth.createUserWithEmailAndPassword(email, password);
    const additionalData = { displayName };
    yield getSnapshotFromUserAuth(user, additionalData);
    // yield call(handleUserProfile,{ userAuth: user, additionalData: {displayName} });
  } catch (exceptionErr) {
    console.log(exceptionErr);

    const err = [exceptionErr.message];
    yield put(userError(err));
    return;
  }
}

export function* onSignUpUserStart() {
  yield takeLatest(userTypes.SIGN_UP_USER_START, signUpUser);
}

export function* resetPassword({ payload: { email } }) {
  try {
    yield call(handleResetPasswordAPI, email);
    yield put(resetPasswordSuccess());
  } catch (err) {
    yield put(userError(err));
    console.log(err);
  }
}

export function* onResetPasswordStart() {
  yield takeLatest(userTypes.RESET_PASSWORD_START, resetPassword);
}

export function* googleSignIn() {
  try {
    const { user } = yield auth.signInWithPopup(GoogleProvider);
    yield getSnapshotFromUserAuth(user);
  } catch (err) {
    console.log(err);
  }
}

export function* onGoogleSignInStart() {
  yield takeLatest(userTypes.GOOGLE_SIGN_IN_START, googleSignIn);
}

export default function* userSagas() {
  yield all([
    call(onHandleSubscriptionUpdateStart),
    call(onEmailSignInStart),
    call(onCheckUserSession),
    call(onSignOutUserStart),
    call(onSignUpUserStart),
    call(onResetPasswordStart),
    call(onGoogleSignInStart),
  ]);
}
