export const checkUserIsAdmin = (currentUser) => {
  if (!currentUser || !Array.isArray(currentUser.userRoles)) return false;

  const { userRoles } = currentUser;

  if (userRoles.includes("admin")) return true;

  return false;
};

// const TEST_URL = "http://localhost:5000/clockwork-9ed2c/us-central1/app";
const PROD_URL = "https://us-central1-clockwork-9ed2c.cloudfunctions.net/app";

export const checkout = (customerId) => {
  fetch(PROD_URL + "/api/v1/create-subscription-checkout-session", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    body: JSON.stringify({
      plan: 1,
      customerId: customerId,
    }),
  })
    .then((res) => {
      if (res.ok) return res.json();
      console.log(res);
      return res.json().then((json) => Promise.reject(json));
    })
    .then(({ session }) => {
      window.location = session.url;
    })
    .catch((e) => {
      console.log(e.error);
    });
};
