import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Pricing from "../pages/Pricing";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});

const WithSubscription = (props) => {
  const history = useHistory();
  const { currentUser } = useSelector(mapState);

  const isCurrentUserSubscribed = () => {
    console.log(currentUser);
    if (
      currentUser &&
      currentUser.subscription &&
      currentUser.subscription.planId &&
      currentUser.subscription.planEndDate
    ) {
      if (currentUser.subscription.planId.length === 0) {
        return false;
      }

      if (currentUser.subscription.planEndDate === 0) {
        return false;
      }
      const endDate = new Date(currentUser.subscription.planEndDate);
      const currentDate = new Date();

      if (currentDate > endDate) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!currentUser) {
      history.push("/");
    }
  }, [currentUser, history]);

  return isCurrentUserSubscribed() ? (
    <div>{props.children}</div>
  ) : (
    <Pricing text="Please subscribe to see products" />
  );
};

export default WithSubscription;
