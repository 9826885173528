import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

function Footer() {
  return (
    <div className="footer-container">
      {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join our exclusive membership to receive the latest news and trends
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--footer'>Subscribe</Button>
          </form>
        </div>
      </section> */}
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <Link to="/contact-us">
              <h2 style={{ fontSize: "17px" }}>Contact Us</h2>
            </Link>
          </div>
        </div>
        <div className="footer-link-items">
          <Link to="/terms-and-conditions">
            <h2 style={{ fontSize: "17px" }}>Terms & Conditions</h2>
          </Link>
        </div>
        <div className="footer-link-items">
          <Link to="/privacy-policy">
            <h2 style={{ fontSize: "17px" }}>Privacy Policy</h2>
          </Link>
        </div>
        <div className="footer-link-items">
          <Link to="/pricing">
            <h2 style={{ fontSize: "17px" }}>Pricing</h2>
          </Link>
        </div>
        <div className="footer-link-items">
          <Link to="/login">
            <h2 style={{ fontSize: "17px" }}>Login</h2>
          </Link>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <small className="website-rights">ARBITHUB © 2023</small>
          <div className="social-icons">
            <a
              className="social-icon-link"
              href="https://www.instagram.com/arbithub"
              target="_blank"
              rel="noopener noreferrer" // Adding a rel attribute for security
              aria-label="Instagram"
            >
              <FaInstagram />
            </a>
            <a
              className="social-icon-link"
              href="https://www.youtube.com/@arbithub"
              target="_blank"
              rel="noopener noreferrer" // Adding a rel attribute for security
              aria-label="Youtube"
            >
              <FaYoutube />
            </a>

            <a
              className="social-icon-link"
              href="https://twitter.com/arbithub"
              target="_blank"
              rel="noopener noreferrer" // Adding a rel attribute for security
              aria-label="Twitter"
            >
              <FaTwitter />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
