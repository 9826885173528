import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./PopUp.css";
import "font-awesome/css/font-awesome.min.css";

const mapState = ({ cartData }) => ({
  cartItems: cartData.cartItems,
});

const PopUp = (props) => {
  const { cartItems } = useSelector(mapState);

  const totalNumCartItems = cartItems.length;

  const lastProductList = cartItems.filter((item) => item.lastItem === true);

  let lastProduct = null;

  if (lastProductList.length === 1) {
    lastProduct = lastProductList[0];
  }

  return props.trigger ? (
    <div className="popup">
      <div>
        <div id="add-to-cart">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-radius modal-shadow">
              <button
                onClick={() => props.setTrigger(false)}
                className="btn dismiss-button fa fa-times"
                type="button"
                data-dismiss="modal"
              />
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="success u-s-m-b-30">
                      <div className="success__text-wrap">
                        {lastProduct && lastProduct.quantity === 1 ? (
                          <div>
                            <i className="fa fa-check" />
                            <span>Item is added successfully!</span>
                          </div>
                        ) : (
                          <div>
                            <i className="fa fa-times-circle"></i>
                            <span>Item is already in favorites!</span>
                          </div>
                        )}
                      </div>
                      {lastProduct && (
                        <div>
                          <div className="success__img-wrap">
                            <img
                              className="u-img-fluid"
                              src={lastProduct.productThumbnail}
                              alt="popupImage"
                            />
                          </div>
                          <div className="success__info-wrap">
                            <span className="success__name">
                              {lastProduct.name}
                            </span>
                            <span className="success__price">
                              +${lastProduct.profit}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="s-option">
                      <span className="s-option__text">
                        {totalNumCartItems} item (s) in your favorites
                      </span>
                      <div className="s-option__link-box">
                        <Link
                          className="s-option__link btn--e-white-brand-shadow"
                          data-dismiss="modal"
                          onClick={() => props.setTrigger(false)}
                        >
                          CONTINUE
                        </Link>
                        <Link
                          to="/favorites"
                          className="s-option__link btn--e-white-brand-shadow"
                        >
                          VIEW FAVORITES
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
};

export default PopUp;
