import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ProductDetailCard from "../ProductDetailCard";

const ProductDetails = ({
  currentRank,
  profit,
  ROI,
  FBAFee,
  unitSales,
  lastSoldTime,
  referralFeePercent,
  averageRank30,
  averageRank90,
  averageRank180,
  listPrice,
  packof,
  buyWalmartUS,
  buyWalmartCA,
  sellPrice,
  walmartNumOfItems,
}) => {
  var buyPrice;

  if (walmartNumOfItems == null) {
    walmartNumOfItems = 1;
  }

  if (buyWalmartUS) {
    buyPrice = buyWalmartUS.price;
  } else {
    buyPrice = buyWalmartCA.price;
  }

  if (packof <= 0) {
    packof = 1;
  }

  const estimatedTax = (buyPrice / 10).toFixed(2);

  const totalCost = (
    (parseInt(packof) * (buyPrice + parseFloat(estimatedTax))) /
    parseInt(walmartNumOfItems)
  ).toFixed(2);

  const totalCostHover = `${
    parseInt(packof) / parseInt(walmartNumOfItems).toFixed(2)
  } (bundle) x ($${buyPrice} (buy price) + $${estimatedTax} (estimated tax)) = $${totalCost} (total buy
    cost)`;

  const referralFee = ((referralFeePercent / 100) * sellPrice).toFixed(2);
  const justFBA = (FBAFee - referralFee).toFixed(2);

  const feeHover = `$${referralFee} (referral fee) + $${justFBA} (fba fee) = $${FBAFee} (total amazon fee)`;
  const profitHover = `$${sellPrice} (sell price) - $${totalCost} (total buy cost) - $${FBAFee} (total amazon fee) = $${profit} (profit)`;

  const profitMargin = ((profit / sellPrice) * 100).toFixed(2);
  const profitMarginHover = `$${profit} (profit) / $${sellPrice} (sell price) = ${profitMargin}% (profit margin)`;

  return (
    <Container
      style={{
        fontSize: "13px",
        color: "#333",
      }}
    >
      <Row style={{ textAlign: "center", padding: "10px" }}>
        <ProductDetailCard
          title="total buy cost"
          content={totalCost}
          hover={totalCostHover}
        />

        {sellPrice ? (
          <ProductDetailCard
            title="total amazon fees"
            content={FBAFee}
            hover={feeHover}
          />
        ) : (
          <ProductDetailCard
            title="total amazon fees"
            content="unknown (out of stock)"
          />
        )}

        {sellPrice ? (
          <ProductDetailCard
            title="profit"
            content={"$" + profit}
            hover={profitHover}
          />
        ) : (
          <ProductDetailCard title="profit" content="unknown (out of stock)" />
        )}
        {sellPrice ? (
          <ProductDetailCard
            title="profit margin"
            content={profitMargin + "%"}
            hover={profitMarginHover}
          />
        ) : (
          <ProductDetailCard
            title="profit margin"
            content="unknown (out of stock)"
          />
        )}

        {listPrice !== -1 ? (
          <ProductDetailCard title="list price" content={"$ " + listPrice} />
        ) : (
          <ProductDetailCard title="list price" content="unknown" />
        )}
      </Row>
    </Container>
  );
};

export default ProductDetails;
