import React from "react";
import "./styles.scss";
import Container from "react-bootstrap/Container";
import PricingDetails from "../../components/Pricing";

const Pricing = ({ text }) => {
  return (
    <Container
      style={{ paddingLeft: "0px", paddingRight: "0px" }}
      className="center-text"
    >
      <Container>
        <PricingDetails text={text} />
      </Container>
    </Container>
  );
};

export default Pricing;
