import React from 'react';
import HeroSection from '../../components/HeroSection';
import {homeObjOne, homeObjTwo, homeObjThree, homeObjFour} from './Data'


const Home = props =>{
    return (
        <>
            <HeroSection {...homeObjOne}/>
            <HeroSection {...homeObjThree}/>
            <HeroSection {...homeObjTwo}/>
            <HeroSection {...homeObjFour}/>
        </>
    );
}

export default Home;