import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../assests/logotry.png";
import {
  resetPasswordStart,
  resetUserState,
  userError,
} from "./../../redux/User/user.actions";
import "./styles.scss";

const mapState = ({ user }) => ({
  resetPasswordSuccess: user.resetPasswordSuccess,
  userErr: user.userErr,
});

const Recovery = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { resetPasswordSuccess, userErr } = useSelector(mapState);
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (resetPasswordSuccess) {
      dispatch(resetUserState());
      history.push("/login");
    }

    return () => {
      dispatch(userError([]));
    };
  }, [resetPasswordSuccess, dispatch, history]);

  useEffect(() => {
    if (Array.isArray(userErr)) {
      setErrors(userErr);
    }
  }, [userErr]);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(resetPasswordStart({ email }));
  };

  return (
    <div className="signin">
      <div className="wrap">
        <div className="login">
          <h1>
            <Link to="/">
              <img style={{ width: "100%" }} src={Logo} alt="logo" />
            </Link>
          </h1>

          <p className="trouble">Trouble Logging In?</p>

          <p className="troubled">
            Please provide your email address, we will send you a link to get
            back into your account.
          </p>

          <form onSubmit={handleSubmit}>
            {errors.length > 0 && (
              <ul>
                {errors.map((e, index) => {
                  return <li key={index}>{e}</li>;
                })}
              </ul>
            )}
            <input
              placeholder="Email Address"
              type="text"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <input
              className="login_button"
              type="submit"
              value="Send Login Link"
            />
            <br />
          </form>
          <div className="divider">
            <b>OR</b>
          </div>

          <div className="fbwrapper">
            <div className="fb">
              <small>
                <Link to="/pricing">Create New Account</Link>
              </small>
            </div>
          </div>
        </div>

        <div className="backTo">
          <p>
            <Link to="/login">Back To Login</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Recovery;
