import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FloatingLabel, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./styles.scss";
import { Link } from "react-router-dom";
import emailjs from "@emailjs/browser";
import Modal from "react-bootstrap/Modal";

const Contact = (props) => {
  const [validated, setValidated] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [topic, setTopic] = useState("");
  const [message, setMessage] = useState("");

  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (validated) {
      // This block of code will run whenever the 'validated' state changes.

      const formData = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        topic: topic,
        message: message,
      };
      console.log(formData);
    }
  }, [validated]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }
    setValidated(true);

    emailjs
      .sendForm(
        "service_ap50n78",
        "template_s5nom29",
        event.currentTarget,
        "q4MT5_O8SngGMvQx2"
      )
      .then(
        (result) => {
          setShowModal(true);
          setLoading(false);
        },
        (error) => {
          console.log(error.text);
        }
      );

    emailjs
      .send(
        "service_064b53u",
        "template_45otf2r",
        { to_email: email, to_name: firstName + " " + lastName },
        "VS1ACg9fR3xvt25_A"
      )
      .then(
        (result) => {},
        (error) => {
          console.log(error.text);
        }
      );
  };

  const handleFirstNameChange = ({ target: { value } }) => {
    setFirstName(value);
  };

  const handleLastNameChange = ({ target: { value } }) => {
    setLastName(value);
  };

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
  };

  const handleTopicChange = ({ target: { value } }) => {
    setTopic(value);
  };

  const handleMessageChange = ({ target: { value } }) => {
    setMessage(value);
  };

  return (
    <div>
      <div className="local-bootstrap contact-form">
        <Modal
          show={showModal}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>Thank you</Modal.Title>
          </Modal.Header>
          <Modal.Body>We'll be in contact with you shortly.</Modal.Body>
          <Modal.Footer>
            <Link to="/">
              <Button
                style={{
                  width: "164px",
                  height: "45px",
                  lineHeight: "45px",
                  padding: "0 15px",
                  fontFamily: "GothamBold, sans-serif",
                  fontSize: "14px",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: "#ffffff",
                  borderRadius: "5px",
                  backgroundColor: "#013D6F",
                  margin: "0 auto",
                  border: "none",
                  cursor: "pointer",
                }}
                type="submit"
              >
                Return to homepage
              </Button>
            </Link>
          </Modal.Footer>
        </Modal>

        <Form validated={validated} onSubmit={handleSubmit}>
          <Row>
            <Col className="text-center">
              <header className="pricing-header price-font">
                <h1
                  style={{
                    fontSize: "32px",
                    color: "hsl(227, 35%, 25%)",
                    fontWeight: "bold",
                  }}
                >
                  Contact Us Today
                </h1>
              </header>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                name="firstName"
                controlId="firstName"
                label="First name"
                className="mb-3 contact-text"
              >
                <Form.Control
                  name="firstName"
                  onChange={handleFirstNameChange}
                  required
                  placeholder="First name"
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                name="lastName"
                controlId="lastName"
                label="Last name"
                className="mb-3 contact-text"
              >
                <Form.Control
                  name="lastName"
                  onChange={handleLastNameChange}
                  required
                  placeholder="Last name"
                />
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <FloatingLabel
                name="email"
                controlId="email"
                label="Email address"
                className="mb-3 contact-text"
              >
                <Form.Control
                  name="email"
                  onChange={handleEmailChange}
                  required
                  type="email"
                  placeholder="name@example.com"
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel
                name="topic"
                controlId="topic"
                label="Choose a topic"
              >
                <Form.Select
                  name="topic"
                  onChange={handleTopicChange}
                  required
                  aria-label="Floating label select example"
                >
                  <option value="">Open select menu</option>
                  <option value="feedback">Share your feedback</option>
                  <option value="complaint">File a complaint</option>
                  <option value="cancelSubscription">
                    Cancel your subscription
                  </option>
                  <option value="requestDemo">Request a demo</option>
                </Form.Select>
              </FloatingLabel>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                name="message"
                onChange={handleMessageChange}
                required
                className="contact-textarea"
                as="textarea"
                placeholder="Your message"
                rows={3}
              />
            </Col>
          </Row>
          <Row>
            <Col className="contact-center-div">
              <Button
                style={{
                  width: "164px",
                  height: "45px",
                  lineHeight: "45px",
                  padding: "0 15px",
                  fontFamily: "GothamBold, sans-serif",
                  fontSize: "14px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  letterSpacing: "0.39px",
                  textAlign: "center",
                  color: "#ffffff",
                  borderRadius: "5px",
                  backgroundColor: "#013D6F",
                  margin: "0 auto",
                  border: "none",
                }}
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? "Sending…" : "Send"}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default Contact;
