import React from "react";
import { Col } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAmazon,
  faGoogle,
  faFontAwesome,
} from "@fortawesome/free-brands-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./styles.scss";

const ProductButtons = ({ ASIN, buyWalmartCA, buyWalmartUS, name }) => {
  const searchQuery = name.replace(" ", "+");
  const googleSearchUrl = `https://www.google.com/search?q=${searchQuery}`;

  var market = 0;
  var marketExt;

  if (buyWalmartCA) {
    market = 6;
    marketExt = "ca";
  } else {
    market = 1;
    marketExt = "com";
  }

  const amazonURL = `https://sellercentral.amazon.${marketExt}/product-search/search?q=${ASIN}&ref_=xx_catadd_dnav_xx`;

  const amazonListTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      List Amazon product
    </Tooltip>
  );

  const googleSearchTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Search for product title on Google
    </Tooltip>
  );

  const keepaTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      View chart on Keepa
    </Tooltip>
  );

  return (
    <Container>
      <Row style={{ textAlign: "center", padding: "10px" }}>
        <Col>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={amazonListTooltip}
          >
            <a href={amazonURL} target="_blank" rel="noopener noreferrer">
              <button className="amazon-button">
                <FontAwesomeIcon icon={faAmazon} />
              </button>
            </a>
          </OverlayTrigger>
        </Col>
        <Col>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={googleSearchTooltip}
          >
            <a href={googleSearchUrl} target="_blank" rel="noopener noreferrer">
              <button className="amazon-button">
                <FontAwesomeIcon icon={faGoogle} />
              </button>
            </a>
          </OverlayTrigger>
        </Col>
        <Col>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={keepaTooltip}
          >
            <a
              href={"https://keepa.com/#!product/" + market + "-" + ASIN}
              className="google-login-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="amazon-button">
                <FontAwesomeIcon icon={faFontAwesome} />
              </button>
            </a>
          </OverlayTrigger>
        </Col>
      </Row>
    </Container>
  );
};

export default ProductButtons;
