import React from "react";
import Container from "react-bootstrap/Container";
import PolicyDetails from "../../components/PolicyDetails";

const PrivacyPolicy = () => {
  return (
    <Container className="center-text">
      <Container>
        <PolicyDetails text="Privacy Policy" />
      </Container>
    </Container>
  );
};

export default PrivacyPolicy;
