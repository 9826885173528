import React from "react";
import { useSelector, useDispatch } from "react-redux";
import noImage from "./../../assests/no-image-available.jpg";
import { removeCartItem } from "./../../redux/Cart/cart.actions";
import "./styles.scss";

const mapState = ({ cartData }) => ({
  cartItems: cartData.cartItems,
});

const ProductImage = ({ imageLink }) => {
  if (imageLink && imageLink !== "") {
    return <img className="aspect__img" src={imageLink} alt="aspect" />;
  } else {
    return <img className="aspect__img" src={noImage} alt="aspect" />;
  }
};

const Checkout = (props) => {
  const { cartItems } = useSelector(mapState);
  const dispatch = useDispatch();

  const handleRemoveCartItem = (documentID) => {
    dispatch(
      removeCartItem({
        documentID,
      })
    );
  };

  return (
    <div>
      <div id="app">
        <div className="app-content">
          <div className="u-s-p-y-90">
            <div className="container">
              <div className="row">
                {/* <div className="col-lg-3 col-md-12">
            </div> */}
                <div className="col-lg-10 col-md-12">
                  <div className="shop-p">
                    <div className="shop-p__toolbar u-s-m-b-30">
                      <div className="shop-p__meta-wrap u-s-m-b-60">
                        <span className="shop-p__meta-text-1">
                          MY FAVORITE PRODUCTS{" "}
                        </span>
                      </div>
                    </div>

                    <div className="shop-p__collection">
                      <div className="row is-list-active">
                        {cartItems.length < 1 && (
                          <div className="col-lg-4 col-md-6 col-sm-6">
                            <h7 id="iconic_span2">
                              You have no items in your favorites. Please add
                              product to favorites from products page.{" "}
                            </h7>
                          </div>
                        )}

                        {cartItems.map((product, index) => {
                          const {
                            productThumbnail,
                            buyAmazonUS,
                            buyWalmartUS,
                            buyWalmartCA,
                            buyAmazonCA,
                            buyEbayUS,
                            sellLink,
                            sellMarket,
                            sellRank,
                            sellPrice,
                            name,
                            category,
                            profitRatio,
                            profit,
                            lastUpdated,
                            unitSales,
                            numOfSellers,
                            documentID,
                          } = product;

                          return (
                            <div
                              key={documentID}
                              className="col-lg-4 col-md-6 col-sm-6"
                            >
                              <div key={documentID} className="product-m">
                                <div className="product-m__thumb">
                                  <div className="aspect aspect--bg-grey aspect--square u-d-block">
                                    <ProductImage
                                      imageLink={productThumbnail}
                                    />
                                  </div>

                                  <div className="product-m__add-cart">
                                    <span
                                      onClick={() =>
                                        handleRemoveCartItem(documentID)
                                      }
                                      className="deletebtn--e-brand"
                                      data-modal="modal"
                                      data-modal-id="#add-to-cart"
                                    >
                                      Remove From Favorites
                                    </span>
                                  </div>
                                </div>
                                <div className="product-m__content">
                                  <div className="product-m__category">
                                    <span href="shop-side-version-2.html">
                                      {category}
                                    </span>
                                  </div>
                                  <div className="product-m__name">
                                    <span href="product-detail.html">
                                      {name}
                                    </span>
                                  </div>
                                  <div className="product-m__rating gl-rating-style">
                                    +${profit}
                                    <span className="product-m__review">
                                      ({profitRatio} profit)
                                    </span>
                                  </div>
                                  <div className="product-m__hover">
                                    <div className="product-m__preview-description">
                                      <div id="social-platforms">
                                        <div
                                          id="mobile-version"
                                          className="row no-gutters"
                                        >
                                          <div className="left-side">
                                            <h7
                                              style={{ margin: "30px" }}
                                              id="iconic_span2"
                                            >
                                              Marketplaces to Buy
                                            </h7>
                                            {buyAmazonUS && (
                                              <a
                                                className="btn btn-icon btn-biz"
                                                rel="noreferrer"
                                                target="_blank"
                                                href={buyAmazonUS.link}
                                              >
                                                <span id="iconic_span">
                                                  ${buyAmazonUS.price} in Amazon
                                                  US
                                                </span>
                                              </a>
                                            )}
                                            {buyWalmartUS && (
                                              <a
                                                className="btn btn-icon btn-biz"
                                                rel="noreferrer"
                                                target="_blank"
                                                href={buyWalmartUS.link}
                                              >
                                                <span id="iconic_span">
                                                  ${buyWalmartUS.price} in
                                                  Walmart US
                                                </span>
                                              </a>
                                            )}
                                            {buyAmazonCA && (
                                              <a
                                                className="btn btn-icon btn-biz"
                                                rel="noreferrer"
                                                target="_blank"
                                                href={buyAmazonCA.link}
                                              >
                                                <span id="iconic_span">
                                                  ${buyAmazonCA.price} in Amazon
                                                  CA
                                                </span>
                                              </a>
                                            )}
                                            {buyEbayUS && (
                                              <a
                                                className="btn btn-icon btn-biz"
                                                rel="noreferrer"
                                                target="_blank"
                                                href={buyEbayUS.link}
                                              >
                                                <span id="iconic_span">
                                                  ${buyEbayUS.price} in Ebay US
                                                </span>
                                              </a>
                                            )}
                                          </div>
                                          <div className="right-side">
                                            <h7
                                              style={{ margin: "30px" }}
                                              id="iconic_span2"
                                            >
                                              Marketplace to Sell
                                            </h7>
                                            <a
                                              className="btn btn-icon btn-biz"
                                              rel="noreferrer"
                                              target="_blank"
                                              href={sellLink}
                                            >
                                              <span id="iconic_span">
                                                ${sellPrice} in {sellMarket}
                                              </span>
                                            </a>
                                            <div className="listed_features">
                                              <p className="product_info">
                                                <strong>#{sellRank}</strong> in
                                                Pet Supplies{" "}
                                              </p>
                                              {unitSales > 0 && (
                                                <p className="product_info">
                                                  Unit Sales per Month:{" "}
                                                  <strong>{unitSales}</strong>{" "}
                                                </p>
                                              )}
                                              <p className="product_info">
                                                Number of Sellers:{" "}
                                                <strong>{numOfSellers}</strong>{" "}
                                              </p>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          id="normal-version"
                                          className="row no-gutters"
                                        >
                                          <div className="col">
                                            <div className="left-side">
                                              <h7
                                                style={{ margin: "30px" }}
                                                id="iconic_span2"
                                              >
                                                Marketplaces to Buy
                                              </h7>
                                              {buyAmazonUS && (
                                                <a
                                                  className="btn btn-icon btn-biz"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={buyAmazonUS.link}
                                                >
                                                  <span id="iconic_span">
                                                    ${buyAmazonUS.price} in
                                                    Amazon US
                                                  </span>
                                                </a>
                                              )}
                                              {buyWalmartCA && (
                                                <a
                                                  className="btn btn-icon btn-biz"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={buyWalmartCA.link}
                                                >
                                                  <span id="iconic_span">
                                                    ${buyWalmartCA.price} in
                                                    Walmart CA
                                                  </span>
                                                </a>
                                              )}
                                              {buyWalmartUS && (
                                                <a
                                                  className="btn btn-icon btn-biz"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={buyWalmartUS.link}
                                                >
                                                  <span id="iconic_span">
                                                    ${buyWalmartUS.price} in
                                                    Walmart US
                                                  </span>
                                                </a>
                                              )}
                                              {buyAmazonCA && (
                                                <a
                                                  className="btn btn-icon btn-biz"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={buyAmazonCA.link}
                                                >
                                                  <span id="iconic_span">
                                                    ${buyAmazonCA.price} in
                                                    Amazon CA
                                                  </span>
                                                </a>
                                              )}
                                              {buyEbayUS && (
                                                <a
                                                  className="btn btn-icon btn-biz"
                                                  rel="noreferrer"
                                                  target="_blank"
                                                  href={buyEbayUS.link}
                                                >
                                                  <span id="iconic_span">
                                                    ${buyEbayUS.price} in Ebay
                                                    US
                                                  </span>
                                                </a>
                                              )}
                                            </div>
                                          </div>
                                          <div className="col">
                                            <div className="right-side">
                                              <h7
                                                style={{ margin: "30px" }}
                                                id="iconic_span2"
                                              >
                                                Marketplace to Sell
                                              </h7>
                                              <a
                                                className="btn btn-icon btn-biz"
                                                rel="noreferrer"
                                                target="_blank"
                                                href={sellLink}
                                              >
                                                <span id="iconic_span">
                                                  ${sellPrice} in {sellMarket}
                                                </span>
                                              </a>
                                              <div className="listed_features">
                                                <p className="product_info">
                                                  <strong>#{sellRank}</strong>{" "}
                                                  in Pet Supplies{" "}
                                                </p>
                                                {unitSales > 0 && (
                                                  <p className="product_info">
                                                    Unit Sales per Month:{" "}
                                                    <strong>{unitSales}</strong>{" "}
                                                  </p>
                                                )}
                                                <p className="product_info">
                                                  Number of Sellers:{" "}
                                                  <strong>
                                                    {numOfSellers}
                                                  </strong>{" "}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="product-m__wishlist">
                                      <p>Last Updated: {lastUpdated}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
