import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  emailSignInStart,
  googleSignInStart,
  userError,
} from "./../../redux/User/user.actions";

import "./styles.scss";
import Logo from "./../../assests/logotry.png";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
  userErr: user.userErr,
});

const SignIn = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { currentUser, userErr } = useSelector(mapState);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (currentUser) {
      resetForm();
      history.push("/");
    }

    return () => {
      dispatch(userError([]));
    };
  }, [currentUser, dispatch, history]);

  useEffect(() => {
    if (Array.isArray(userErr)) {
      setErrors(userErr);
    }
  }, [userErr]);

  const resetForm = () => {
    setEmail("");
    setPassword("");
    setErrors([]);
    dispatch(userError([]));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(emailSignInStart({ email, password }));
  };

  const handleGoogleSignIn = () => {
    dispatch(googleSignInStart());
  };

  return (
    <div className="signin">
      <div className="wrap">
        <div className="login">
          <h1>
            <Link to="/">
              <img style={{ width: "100%" }} src={Logo} alt="logo" />
            </Link>
          </h1>

          {errors.length > 0 && (
            <ul>
              {errors.map((err, index) => {
                return (
                  <li style={{ color: "red", width: "300px" }} key={index}>
                    {err}
                  </li>
                );
              })}
            </ul>
          )}
          <form onSubmit={handleSubmit}>
            <input
              placeholder="Email Address"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />

            <input
              placeholder="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              name="password"
            />

            <input className="login_button" type="submit" value="Login" />
            <br />
          </form>
          <div className="divider">
            <b>OR</b>
          </div>

          <div className="fbwrapper">
            <div className="fb">
              <small onClick={handleGoogleSignIn}>Login with Google</small>
            </div>
          </div>
          <div className="forgotwrapper">
            <Link to="/recovery">
              <div className="forgot">
                <small>Forgot password?</small>
              </div>
            </Link>
          </div>
        </div>

        <div className="infobox">
          <p>
            Don't have an account? <Link to="/pricing">Sign up</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
