import { firestore } from "./../../firebase/utils";

export const handleAddProduct = (product) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("products")
      .doc()
      .set(product)
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleFetchProducts = ({
  amzCA_BF,
  amzCOM_BF,
  WALCA_BF,
  WAL_BF,
  EBAY_BF,
  amzCA_ST,
  amzCOM_ST,
  amazonNotComp,
  buyBoxOpp,
  outOfStock,
  notOutOfStock,
  category,
  sortBy,
  buyPriceLessThan20,
  startAfterDoc,
  persistProducts = [],
}) => {
  return new Promise((resolve, reject) => {
    const pageSize = 12;

    console.log(sortBy);
    let ref = firestore.collection("products");

    if (sortBy && sortBy !== "") {
      if (sortBy === "Sort By: Newest Items") {
        ref = ref.orderBy("createdDate", "desc").limit(pageSize);
      } else if (sortBy === "Sort By: Latest Items") {
        ref = ref.orderBy("createdDate").limit(pageSize);
      } else if (sortBy === "Sort By: Best Sellers Rank") {
        ref = ref.orderBy("sellRank").limit(pageSize);
      } else if (sortBy === "Sort By: Highest Profit") {
        ref = ref.orderBy("profit", "desc").limit(pageSize);
      } else if (sortBy === "Sort By: Highest Profit Ratio") {
        ref = ref.orderBy("profitRatio", "desc").limit(pageSize);
      } else if (sortBy === "Sort By: Lowest Buy Price") {
        ref = ref.orderBy("lowestBuyPrice").limit(pageSize);
      } else if (sortBy === "Sort By: Highest Buy Price") {
        ref = ref.orderBy("lowestBuyPrice", "desc").limit(pageSize);
      } else if (sortBy === "Sort By: Highest Sell Price") {
        ref = ref.orderBy("sellPrice", "desc").limit(pageSize);
      } else if (sortBy === "Sort By: Highest Unit Sales") {
        ref = ref.orderBy("unitSales", "desc").limit(pageSize);
      } else {
        ref = ref.orderBy("createdDate", "desc").limit(pageSize);
      }
    } else {
      ref = ref.orderBy("createdDate", "desc").limit(pageSize);
    }

    let BF_LIST = [];

    if (category && category !== "" && category !== "All Products")
      ref = ref.where("category", "==", category);

    // if (fastShipping) ref = ref.where("fastShipping", "==", true);

    if (buyPriceLessThan20) ref = ref.where("buyPriceLessThan20", "==", true);

    if (buyBoxOpp) ref = ref.where("buyboxOpportunity", "==", true);

    if (amazonNotComp) ref = ref.where("amazonNotComp", "==", true);

    if (
      amzCA_BF !== false ||
      amzCOM_BF !== false ||
      WAL_BF !== false ||
      EBAY_BF !== false ||
      WALCA_BF !== false
    ) {
      if (amzCA_BF) BF_LIST.push("Amazon CA");

      if (amzCOM_BF) BF_LIST.push("Amazon US");

      if (WALCA_BF) BF_LIST.push("Walmart CA");

      if (WAL_BF) BF_LIST.push("Walmart US");

      if (EBAY_BF) BF_LIST.push("Ebay US");

      if (BF_LIST.length > 0) {
        ref = ref.where("buyMarket", "array-contains-any", BF_LIST);
      }
    }

    if (
      (amzCA_ST === true && amzCOM_ST === false) ||
      (amzCA_ST === false && amzCOM_ST === true)
    ) {
      if (amzCA_ST) ref = ref.where("sellMarket", "==", "Amazon CA");

      if (amzCOM_ST) ref = ref.where("sellMarket", "==", "Amazon US");
    }

    if (
      (notOutOfStock === true && outOfStock === false) ||
      (notOutOfStock === false && outOfStock === true)
    ) {
      if (notOutOfStock) ref = ref.where("outOfStock", "==", false);

      if (outOfStock) ref = ref.where("outOfStock", "==", true);
    }

    // if(!((buyPrice0_30 && buyPirce30_70 &&buyPrice_70_) || (!buyPrice0_30 && !buyPirce30_70 && !buyPrice_70_))){

    //     if(buyPrice0_30 && !buyPirce30_70 && !buyPrice_70_){
    //         ref = ref.where("buyPriceOptions.crit1", "==", true);
    //     }
    //     else if(!buyPrice0_30 && buyPirce30_70 && !buyPrice_70_){
    //         ref = ref.where("buyPriceOptions.crit2", "==", true);
    //     }
    //     else if(!buyPrice0_30 && !buyPirce30_70 && buyPrice_70_){
    //         ref = ref.where("buyPriceOptions.crit3", "==", true);
    //     }
    //     else if(buyPrice0_30 && buyPirce30_70 && !buyPrice_70_){
    //         ref = ref.where("buyPriceOptions.crit1_or_crit2", "==", true);
    //     }
    //     else if(buyPrice0_30 && !buyPirce30_70 && buyPrice_70_){
    //         ref = ref.where("buyPriceOptions.crit1_or_crit3", "==", true);
    //     }
    //     else if(!buyPrice0_30 && buyPirce30_70 && buyPrice_70_){
    //         ref = ref.where("buyPriceOptions.crit2_or_crit3", "==", true);
    //     }
    // }

    // if(!((profitRange20_50 && profitRange50_100 &&profitRange_100_) || (!profitRange20_50 && !profitRange50_100 && !profitRange_100_))){

    //     if(profitRange20_50 && !profitRange50_100 && !profitRange_100_){
    //         ref = ref.where("profitRangeOptions.crit1", "==", true);
    //     }
    //     else if(!profitRange20_50 && profitRange50_100 && !profitRange_100_){
    //         ref = ref.where("profitRangeOptions.crit2", "==", true);
    //     }
    //     else if(!profitRange20_50 && !profitRange50_100 && profitRange_100_){
    //         ref = ref.where("profitRangeOptions.crit3", "==", true);
    //     }
    //     else if(profitRange20_50 && profitRange50_100 && !profitRange_100_){
    //         ref = ref.where("profitRangeOptions.crit1_or_crit2", "==", true);
    //     }
    //     else if(profitRange20_50 && !profitRange50_100 && profitRange_100_){
    //         ref = ref.where("profitRangeOptions.crit1_or_crit3", "==", true);
    //     }
    //     else if(!profitRange20_50 && profitRange50_100 && profitRange_100_){
    //         ref = ref.where("profitRangeOptions.crit2_or_crit3", "==", true);
    //     }
    // }

    // if(!((unitSales0_20 && unitSales20_100 &&unitSales_100_) || (!unitSales0_20 && !unitSales20_100 && !unitSales_100_))){

    //     if(unitSales0_20 && !unitSales20_100 && !unitSales_100_){
    //         ref = ref.where("unitSalesOptions.crit1", "==", true);
    //     }
    //     else if(!unitSales0_20 && unitSales20_100 && !unitSales_100_){
    //         ref = ref.where("unitSalesOptions.crit2", "==", true);
    //     }
    //     else if(!unitSales0_20 && !unitSales20_100 && unitSales_100_){
    //         ref = ref.where("unitSalesOptions.crit3", "==", true);
    //     }
    //     else if(unitSales0_20 && unitSales20_100 && !unitSales_100_){
    //         ref = ref.where("unitSalesOptions.crit1_or_crit2", "==", true);
    //     }
    //     else if(unitSales0_20 && !unitSales20_100 && unitSales_100_){
    //         ref = ref.where("unitSalesOptions.crit1_or_crit3", "==", true);
    //     }
    //     else if(!unitSales0_20 && unitSales20_100 && unitSales_100_){
    //         ref = ref.where("unitSalesOptions.crit2_or_crit3", "==", true);
    //     }
    // }

    // if(!((numOfSeller0_3 && numOfSeller3_10 &&numOfSeller_10_) || (!numOfSeller0_3 && !numOfSeller3_10 && !numOfSeller_10_))){

    //     if(numOfSeller0_3 && !numOfSeller3_10 && !numOfSeller_10_){
    //         ref = ref.where("numOfSellerOptions.crit1", "==", true);
    //     }
    //     else if(!numOfSeller0_3 && numOfSeller3_10 && !numOfSeller_10_){
    //         ref = ref.where("numOfSellerOptions.crit2", "==", true);
    //     }
    //     else if(!numOfSeller0_3 && !numOfSeller3_10 && numOfSeller_10_){
    //         ref = ref.where("numOfSellerOptions.crit3", "==", true);
    //     }
    //     else if(numOfSeller0_3 && numOfSeller3_10 && !numOfSeller_10_){
    //         ref = ref.where("numOfSellerOptions.crit1_or_crit2", "==", true);
    //     }
    //     else if(numOfSeller0_3 && !numOfSeller3_10 && numOfSeller_10_){
    //         ref = ref.where("numOfSellerOptions.crit1_or_crit3", "==", true);
    //     }
    //     else if(!numOfSeller0_3 && numOfSeller3_10 && numOfSeller_10_){
    //         ref = ref.where("numOfSellerOptions.crit2_or_crit3", "==", true);
    //     }
    // }

    // if(!((bestSalesRank0_150 && bestSalesRank150_300 &&bestSalesRank_300_) || (!bestSalesRank0_150 && !bestSalesRank150_300 && !bestSalesRank_300_))){

    //     if(bestSalesRank0_150 && !bestSalesRank150_300 && !bestSalesRank_300_){
    //         ref = ref.where("bestSalesRankOptions.crit1", "==", true);
    //     }
    //     else if(!bestSalesRank0_150 && bestSalesRank150_300 && !bestSalesRank_300_){
    //         ref = ref.where("bestSalesRankOptions.crit2", "==", true);
    //     }
    //     else if(!bestSalesRank0_150 && !bestSalesRank150_300 && bestSalesRank_300_){
    //         ref = ref.where("bestSalesRankOptions.crit3", "==", true);
    //     }
    //     else if(bestSalesRank0_150 && bestSalesRank150_300 && !bestSalesRank_300_){
    //         ref = ref.where("bestSalesRankOptions.crit1_or_crit2", "==", true);
    //     }
    //     else if(bestSalesRank0_150 && !bestSalesRank150_300 && bestSalesRank_300_){
    //         ref = ref.where("bestSalesRankOptions.crit1_or_crit3", "==", true);
    //     }
    //     else if(!bestSalesRank0_150 && bestSalesRank150_300 && bestSalesRank_300_){
    //         ref = ref.where("bestSalesRankOptions.crit2_or_crit3", "==", true);
    //     }
    // }

    if (startAfterDoc && startAfterDoc !== "undefined" && startAfterDoc !== "")
      ref = ref.startAfter(startAfterDoc);

    ref
      .get()
      .then((snapshot) => {
        const totalCount = snapshot.size;

        const data = [
          ...persistProducts,
          ...snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              documentID: doc.id,
            };
          }),
        ];

        resolve({
          data,
          queryDoc: snapshot.docs[totalCount - 1],
          isLastPage: totalCount < 1,
        });
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const handleDeleteProduct = (documentID) => {
  return new Promise((resolve, reject) => {
    firestore
      .collection("products")
      .doc(documentID)
      .delete()
      .then(() => {
        resolve();
      })
      .catch((err) => {
        reject(err);
      });
  });
};
