import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleSubscriptionUpdateStart } from "../../redux/User/user.actions";
import { Link } from "react-router-dom";
import "./styless.css";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});

const Success = () => {
  const { currentUser } = useSelector(mapState);
  const dispatch = useDispatch();

  useEffect(() => {
    function handlePaymentSuccess() {
      console.log(currentUser);
      if (
        currentUser &&
        currentUser.id &&
        currentUser.subscription &&
        currentUser.subscription.sessionId &&
        !currentUser.subscription.planId
      ) {
        fetch(PROD_URL + "/api/v1/payment-success", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          mode: "cors", // Ensure you're making a CORS request
          body: JSON.stringify({
            sessionId: currentUser.subscription.sessionId,
            firestoreId: currentUser.id,
          }),
        })
          .then((res) => {
            if (res.ok) return res.json();
            return res.json().then((json) => Promise.reject(json));
          })
          .then(
            ({
              message,
              sessionId,
              planId,
              planStartDate,
              planEndDate,
              planDuration,
            }) => {
              if (planId && planStartDate && planEndDate && planDuration) {
                dispatch(
                  handleSubscriptionUpdateStart({
                    sessionId,
                    planId,
                    planStartDate,
                    planEndDate,
                    planDuration,
                  })
                );
              }
            }
          )
          .catch((e) => {
            console.log(e.error);
          });
      }
    }

    handlePaymentSuccess();
  }, [currentUser, dispatch]);

  // const TEST_URL = "http://localhost:5000/clockwork-9ed2c/us-central1/app";
  const PROD_URL = "https://us-central1-clockwork-9ed2c.cloudfunctions.net/app";

  return (
    <section className="container price-font">
      <div style={{ marginTop: "100px" }} className="cardContainer">
        <div className="card">
          <div className="cardTop">
            <h1 id="price">Payment Successful</h1>
          </div>
          <div className="bottomCont">
            <div style={{ margin: "auto" }} className="bottomRight">
              <Link to="/products">
                <button>Start exploring opportunities</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Success;
