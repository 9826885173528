import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { BiCheck } from "react-icons/bi";
import { IconContext } from "react-icons/lib";
import { checkout } from "../../Utils";
import "./Pricing.css";

const mapState = ({ user }) => ({
  currentUser: user.currentUser,
});

function PricingDetails({ text }) {
  const history = useHistory();
  const { currentUser } = useSelector(mapState);
  const [isLoading, setIsLoading] = useState(false);

  const handleFreeTrial = () => {
    if (!currentUser) {
      history.push("/sign-up");
    } else if (
      currentUser &&
      (!currentUser.subscription ||
        (currentUser.subscription && !currentUser.subscription.planId))
    ) {
      checkout(currentUser.id);
      setIsLoading(true);
    } else {
      history.push("/products");
    }
  };

  return (
    <div>
      {isLoading ? (
        <div className="spinner-container">
          <svg
            className="spinner"
            width="65px"
            height="65px"
            viewBox="0 0 66 66"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="path"
              fill="none"
              stroke-width="6"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      ) : (
        <section className="container price-font">
          <header className="pricing-header">
            <h1>{text}</h1>
            <p>Sign up for our 30-day trial</p>
          </header>
          <div className="cardContainer">
            <div className="card">
              <div className="cardTop">
                <h1 id="price">
                  <span style={{ fontSize: "20px" }}>(US)</span>$39.90{" "}
                  <span>/month</span>
                </h1>
              </div>
              <div className="mobilePriceCont">
                <h1 id="price2">
                  <small>(US)</small>39.90 <span> / month</span>
                </h1>
              </div>
              <div className="bottomCont">
                <div className="bottomLeft">
                  <ul>
                    <li>
                      <IconContext.Provider value={{ color: "green" }}>
                        <div>
                          <BiCheck />
                        </div>
                      </IconContext.Provider>
                      <p>Unlimited arbitrage opportunities</p>
                    </li>
                    <li>
                      <IconContext.Provider value={{ color: "green" }}>
                        <div>
                          <BiCheck />
                        </div>
                      </IconContext.Provider>
                      <p>Top 1% profitable products</p>
                    </li>
                    <li>
                      <IconContext.Provider value={{ color: "green" }}>
                        <div>
                          <BiCheck />
                        </div>
                      </IconContext.Provider>
                      <p>Selected from over +600M products</p>
                    </li>
                  </ul>
                </div>
                <div className="bottomRight">
                  {currentUser &&
                  currentUser.subscription &&
                  currentUser.subscription.planId ? (
                    <Link to="/products">
                      <button>Already subscribed</button>
                    </Link>
                  ) : (
                    <button onClick={handleFreeTrial}>
                      Start my free trial
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
}

export default PricingDetails;
