import React, { useState } from "react";
import "./styles.css";
import { useSelector, useDispatch } from "react-redux";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "./../../assests/logotry.png";
import Cover from "./../../assests/cover.png";
import { Link } from "react-router-dom";
import { Button } from "./../../Button";
import { signOutUserStart } from "./../../redux/User/user.actions";

const mapState = ({ user, cartData }) => ({
  currentUser: user.currentUser,
  totalNumCartItems: cartData.cartItems.length,
});

const Header = (props) => {
  const dispatch = useDispatch();
  const { currentUser, totalNumCartItems } = useSelector(mapState);

  const signOut = () => {
    dispatch(signOutUserStart());
  };

  const [click, setClick] = useState(false);
  const [button, setButton] = useState(window.innerWidth <= 960 ? false : true);

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  window.addEventListener("resize", showButton);

  return (
    <header className="navbar">
      <div className="navbar-container container">
        {button ? (
          <Link to="/" className="navbar-logo " onClick={closeMobileMenu}>
            <img src={Logo} alt="logo" />
          </Link>
        ) : (
          <Link
            to="/"
            className="navbar-logo__minimized"
            onClick={closeMobileMenu}
          >
            <img src={Cover} alt="logo" />
          </Link>
        )}

        <div className="menu-icon" onClick={handleClick}>
          {click ? <FaTimes /> : <FaBars />}
        </div>

        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            {button ? (
              <Link
                to="/"
                className="navbar-logo"
                onClick={closeMobileMenu}
              ></Link>
            ) : (
              <Link to="/">
                <img src={Logo} alt="logo" onClick={closeMobileMenu} />
              </Link>
            )}
          </li>

          <li className="nav-item">
            <Link to="/pricing" className="nav-links" onClick={closeMobileMenu}>
              Pricing
            </Link>
          </li>

          {currentUser && (
            <li className="nav-item">
              <Link
                to="/products"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Products
              </Link>
            </li>
          )}

          {currentUser && (
            <li className="nav-item">
              <Link
                to="/favorites"
                className="nav-links"
                onClick={closeMobileMenu}
              >
                Favorites ({totalNumCartItems})
              </Link>
            </li>
          )}

          <li className="nav-item">
            <Link
              to="/contact-us"
              className="nav-links"
              onClick={closeMobileMenu}
            >
              Contact Us
            </Link>
          </li>

          {!currentUser && (
            <li className="nav-item">
              <Link to="/login" className="nav-links" onClick={closeMobileMenu}>
                Login
              </Link>
            </li>
          )}

          {!currentUser && (
            <li className="nav-btn">
              {button ? (
                <Link
                  to="/pricing"
                  className="btn-link"
                  onClick={closeMobileMenu}
                >
                  <Button buttonStyle="btn--outline">FREE TRIAL</Button>
                </Link>
              ) : (
                <Link
                  to="/pricing"
                  className="btn-link"
                  onClick={closeMobileMenu}
                >
                  <Button buttonStyle="btn--outline" buttonSize="btn--mobile">
                    FREE TRIAL
                  </Button>
                </Link>
              )}
            </li>
          )}

          {currentUser && (
            <li className="nav-item">
              <span
                className="nav-links"
                onClick={() => {
                  signOut();
                  closeMobileMenu();
                }}
              >
                Log Out
              </span>
            </li>
          )}
        </ul>
      </div>
      <br />
    </header>
  );
};

Header.defaultProps = {
  currentUser: null,
};

export default Header;
